import {combineReducers} from 'redux';
import plates from './prescanReducer';
import tips from './tipsReducer';

const rootReducer = combineReducers({
  plates,
  tips
});

export default rootReducer;
