import React, {Component} from 'react';
import {connect} from "react-redux";
import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import compose from 'recompose/compose';
import axios from 'axios';
import PropTypes from "prop-types";
import * as prescanActions from "../../actions/prescanActions";
import {bindActionCreators} from "redux";
import loadingGif from "../../../loadgif.gif";
import config from '../../config.json'

class LoadAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      checked: false,
      preparing: false,
      preparingComplete: false,
      initializing: false,
      initializingComplete: false
    };

  }

  componentWillReceiveProps(nextProps) {

  }

  componentWillMount() {
    this.setState({loading: true});
    setTimeout(() => {
      this.setState({loading: false, preparing: true});
      this.prepScan()
    }, 2000)
  }

  prepScan() {
    setTimeout(() => {
      this.setState({preparing: false, initializing: true, preparingComplete: true});
      this.startIntializing()
    }, 3000)
  }

  startIntializing() {
    setTimeout(() => {
      this.setState({initializing: false, initializingComplete: true});
      this.launchExoScan();
    }, 4000)
  }

  launchExoScan() {
    axios.get(config.apiUrl + '/launchExoScan', {
      params: {
        path: config.Nscanpath,
        exptid: this.props.plates.analysis_id
      }
    })
      .then(response => {
        if (response.data.status === 200) {
          console.log('Launch successful')
        }
      })
  }

  render() {
    const {loading, preparing, initializing, preparingComplete, initializingComplete} = this.state;
    return (
      <div className='container-fluid' style={{minHeight: 530, marginTop: 50}}>
        <div className='row'>
          <div className='col-md-2'>
            <img src={loadingGif} alt='loading' width={230}/>
          </div>
          <div className='col-md-6'>
            <div className='row'>
              {!loading ? <Checkbox checked={true} color="success"/> : <Checkbox checked={false}/>}
              <span>
                            <p className='ml-2' style={{marginTop: 20}}>
                                <span>
                                    {loading ? (<span style={{fontWeight: 'bold'}}>
                                        <span>Loading Experiment</span> <span>
                                            {this.props.plates.analysis_name} </span><i
                                        className="fa fa-refresh fa-spin ml-4"></i>
                                    </span>)
                                      :
                                      (<span className='text-muted'>
                                            <span>Loading Experiment</span> <span>
                                                {this.props.plates.analysis_name}</span>
                                        </span>)}
                                </span>
                            </p>
                        </span>
            </div>
            <div className='row'>
              {preparingComplete ? <Checkbox checked={true} color="success"/> : <Checkbox checked={false}/>}
              <span>
                            <p className='ml-2' style={{marginTop: 20}}>
                                <span>
                                    {preparing ? (<span style={{fontWeight: 'bold'}}>
                                        <span>Preparing ExoViewer analysis routines</span> <span>
                                             </span><i className="fa fa-refresh fa-spin ml-4"></i>
                                    </span>)
                                      :
                                      (<span className='text-muted'>
                                            <span>Preparing ExoViewer analysis routines</span> <span>
                                                 </span>
                                        </span>)}
                                </span>
                            </p>
                        </span>
            </div>
            <div className='row'>
              {initializingComplete ? <Checkbox checked={true} color="success"/> : <Checkbox checked={false}/>}
              <span>
                            <p className='ml-2' style={{marginTop: 20}}>
                                <span>
                                    {initializing ? (<span style={{fontWeight: 'bold'}}>
                                        <span>ExoViewer prescan initializing </span><i
                                        className="fa fa-refresh fa-spin ml-4"></i>
                                    </span>)
                                      :
                                      (<span className='text-muted'>
                                            <span>ExoViewer analysis initializing ...</span>
                                        </span>)}
                                </span>
                            </p>
                        </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


LoadAnalysis.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    plates: state.plates
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(prescanActions, dispatch)
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(LoadAnalysis);
