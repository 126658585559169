export var ANALYSIS_NAME = 'ANALYSIS_NAME';
export var ANALYSIS_ID = 'ANALYSIS_ID';
export const CREATE_SCAN = 'CREATE_SCAN';
export const CREATE_EXPER = 'CREATE_EXPER';
export const PRESCANNED = 'PRESCANNED';
export const EXPERIMENT_NAME = 'EXPERIMENT_NAME';
export const POSTSCAN_NAME = 'POSTSCAN_NAME';
export const PERMANENT_NAME = 'PERMANENT_NAME';
export const ANALYSIS_EXPERIMENT = 'ANALYSIS_EXPERIMENT';
export const POSTSCAN_ID = 'POSTSCAN_ID';
export const SELECTED_EXPT = 'SELECTED_EXPT';
export const SHOW_TIP = 'SHOW_TIP';

