import React from "react";
import KitTable from "./kitTable";
import ChipTable from "./chipTable";
import {withStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import * as prescanActions from "../../actions/prescanActions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {bindActionCreators} from "redux";
import config from "../../config.json";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import PreScanChipDetails from './PrescanChipDetails';

//snackbar
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import SnackbarContent from "@material-ui/core/SnackbarContent/SnackbarContent";
import compose from "recompose/compose";
import CheckCircleIcon from "@material-ui/core/SvgIcon/SvgIcon";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 3,
    fontSize: "13px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const {children, classes, onClose} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="p">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 3,
    fontSize: "13px"
  }
}))(MuiDialogContent);


function MySnackbarContent(props) {
  const {classes, className, message, onClose, variant, ...other} = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)}/>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon}/>
        </IconButton>
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit
  }
});

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: "center",
    flexWrap: "wrap"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  paper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    backgroundColor: "#e3f2fd"
  },
  navLink: {
    display: "block",
    color: "#757575",
    fontSize: "14px",
    textDecoration: "none"
  },
  emphasisChips: {
    textDecoration: "underline",
    color: "#2980b9"
  },
  disperse: {
    width: "16px"
  }
});

class Selectchips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chip: [],
      enabled: true,
      newExptName: "",
      status: "",
      open: false,
      chipCount: 0,
      openDialog: false,
      exptid: null,
      exptname: null,
    };
    this.handler = this.handler.bind(this);
    this.chipDetails = this.chipDetails.bind(this);
    this.createGlobalId = this.createGlobalId.bind(this);
  }

  handleClickOpen = () => {
    this.setState({
      openDialog: true
    });
  };

  handleClose = () => {
    this.setState({openDialog: false});
  };

  closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({open: false});
  };

  componentDidMount() {
    if (this.props.plates.key !== 0) {
      axios.get(config.apiUrl + "/displayechips", {
        params: {
          exptid: this.props.plates.key
        }
      })
        .then(response => {
          const res = response.data;
          const arr = [];
          for (var val in res) {
            arr.push(res[val].chip_name);
          }
          this.setState({
            chipCount: arr.length
          });
        });
    }
  }

  chipDetails(count) {
    if (count !== -1) {
      axios.get(config.apiUrl + "/displayechips", {
        params: {
          exptid: this.props.plates.key
        }
      })
        .then(response => {
          const res = response.data;
          const arr = [];
          for (var val in res) {
            arr.push(res[val].chip_name);
          }
          this.setState({
            chipCount: arr.length
          });
        });
    }
  };

  createGlobalId(exptid, exptname) {
    this.setState({
      exptid: exptid,
      exptname: exptname,
    })
  }

  handler(selected) {
    axios.get(config.apiUrl + '/getPlatesById', {
      params: {
        plate_id: selected
      }
    }).then(response => {
      this.setState({
        chip: response.data.chips,
      })
    })
  }

  // startBackgroundAnalysis = () => {
  //   this.setState({
  //     enabled: false,
  //   })
  // };

  changeName = event => {
    this.setState({
      newExptName: event.target.value
    });
  };

  modifyExprName = event => {
    event.preventDefault();
    axios.get(config.apiUrl + "/modifyExperimentName", {
      params: {
        exptid: this.props.plates.key,
        newName: this.state.newExptName
      }
    })
      .then(response => {
        if (response.data.status === 200) {
          this.props.actions.experimentName(this.state.newExptName);
          this.setState({
            status: "success",
            open: true
          });
        } else {
          alert("No changes made, try again.");
        }
      });
  };

  render() {
    const {classes} = this.props;
    const {chip, enabled, open, chipCount, status} = this.state;

    return (
      <div className={classes.root}>
        <div>
          {open && status === "success" && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              open={this.state.open}
              autoHideDuration={4000}
              onClose={this.closeSnackBar}
            >
              <MySnackbarContentWrapper
                onClose={this.closeSnackBar}
                variant="success"
                message="Name changed successfully!"
              />
            </Snackbar>
          )}
        </div>

        <div>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openDialog}
          >
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
              <div className='exo-gradient'>
                <h6 style={{marginLeft: '45%'}} className='exo-name'>CHIPS</h6>
              </div>
            </DialogTitle>
            <DialogContent style={{width: "600px", padding: "20px"}}>
              <PreScanChipDetails/>
            </DialogContent>
          </Dialog>
        </div>

        <div className="ml-5">
          <div className="row col-md-12" style={{marginTop: 25}}>
            <div className="col-md-8">
              <KitTable handler={this.handler}/>
            </div>
            <div className="col-md-4">
              <ChipTable chip={chip} chipDetails={this.chipDetails} createGlobalId={this.createGlobalId}/>
            </div>
          </div>
          <div className="row col-md-12" style={{marginTop: 25}}>
            <div className="col-md-8">
              <form className="container" onSubmit={event => this.modifyExprName(event)}>
                <div className="form-group row col-md-12">
                  <div className="row col-md-12">
                    <label htmlFor="inputSearch" className=" col-md-3 mt-1">
                      Save Experiment as:
                    </label>
                    <input
                      type="text"
                      className="form-control col-sm-6"
                      id="inputSearch"
                      placeholder={this.props.plates.name}
                      onChange={event => this.changeName(event)}
                    />
                    <small className="col-md-3 mt-2">
                      <span className="chipCount" style={{marginLeft: 80}}>
                        <span onMouseEnter={this.handleClickOpen}>{chipCount === 0 ? "No" : chipCount} chips</span>
                      </span>
                    </small>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4">
              <Link to='/prescan/presummary'>
                <button className="exo-btn shadow select-file" type="button"
                        style={{padding: '6px 12px'}}>Proceed to Summary<i class="fas fa-microscope ml-1"
                                                                          style={{fontSize: '13px'}}/>
                </button>
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

Selectchips.propTypes = {
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    plates: state.plates
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(prescanActions, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Selectchips);
