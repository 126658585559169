import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import {lighten} from '@material-ui/core/styles/colorManipulator';
import purple from '../../img/purple.png';
import blue from '../../img/blue.png';
// import green from '../../img/green.png';
import red from '../../img/red.png';
import axios from 'axios';
import config from '../../config.json'
import CreateIcon from '@material-ui/icons/Create'

//snackbar
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import green from '@material-ui/core/colors/green';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import amber from '@material-ui/core/colors/amber';
import compose from "recompose/compose";
import {connect} from "react-redux";


const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const {classes, className, message, onClose, variant, ...other} = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)}/>
          {message}
                </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon}/>
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

// EOF MySnackbarContentWrapper

function createData(name, time, spacer, channel_id, channel_val) {
  switch (channel_id) {
    case 11:
      return {id: 1, name, time, spacer, channel_id: channel_id, color: channel_val};
    case 8:
      return {id: 2, name, time, spacer, channel_id: channel_id, color: channel_val};
    case 4:
      return {id: 3, name, time, spacer, channel_id: channel_id, color: channel_val};
    case 7:
      return {id: 4, name, time, spacer, channel_id: channel_id, color: channel_val};
    default:
      return {id: 5, name, time, spacer, channel_id: channel_id, color: channel_val};

  }
  // return {id: counter, name, time, spacer, channel_id: channel_id, color: channel_val};
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: <span><Typography className="text-muted">Label<span><Tooltip title="Edit label"
                                                                        placement="bottom"><CreateIcon
      style={{fontSize: "19px", marginBottom: 4}} className='ml-1'/></Tooltip></span></Typography></span>
  },
  {id: 'time', numeric: true, disablePadding: false, label: 'Time (s)'},
  {id: 'spacer', numeric: true, disablePadding: false, label: ''},
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              color='primary'
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >

                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>

              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  circles: {
    padding: 5,
    margin: 5,
    display: "inline-block",
    borderRadius: "50%",
    width: 25,
    height: 25,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten("#8acff1", 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const {numSelected, classes} = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Channel Options
        </Typography>
      </div>
      <div className={classes.spacer}/>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 5,
  },
  circles: {
    display: "inline-block",
    borderRadius: "50%",
    width: 17,
    height: 17,
  },
  table: {
    minWidth: 80,
  },
  tableWrapper: {
    overflowX: 'auto',
  },

});


class KitTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'id',
      selected: [],
      data: [],
      chip_id: '',
      added: false,
      open: false,
      status: '',
      autodisabled: true,
      indicators: [
        {
          id: 1,
          indicator: purple,
          color: "#78279b",
          width: 17,
          marginRight: 0,
        },
        {
          id: 2,
          indicator: blue,
          color: "#1b71aa",
          width: 17,
          marginRight: 0,
        },
        {
          id: 3,
          indicator: green,
          color: "#1e944f",
          width: 18,
          marginRight: -2,
        },
        {
          id: 4,
          indicator: red,
          color: "#cf3636",
          width: 16,
          marginRight: 0,
        },
      ],
      page: 0,
      editLabel: false,
      labelEdited: true,
      current_id: '',
      labelName: '',
      rowsPerPage: 5,
    };
  }

  componentWillMount() {
    axios.get(config.apiUrl + '/getchannels')
      .then(response => {
        const res = [];
        for (const val in response.data) {
          res.push(createData(response.data[val].channel_name, response.data[val].time, <span
            className={response.data[val].channel_val}/>, response.data[val].channel_id, response.data[val].channel_val))
        }
        this.setState({
          data: res
        });
        this.setState(state => ({selected: state.data.map(n => n.id)}));
      })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.chip_id != null) {
      this.setState({
        chip_id: nextProps.chip_id,
      });
      this.setState(state => ({selected: state.data.map(n => n.id)}));
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({order, orderBy});
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({selected: state.data.map(n => n.id)}));
      return;
    }
    this.setState({selected: []});
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open: false});
  };


  handleClick = (event, id, channel_id, name, isSelected) => {
    const {selected, chip_id} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    // console.log(id);
    {
      if (!isSelected) {
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }

        axios.get(config.apiUrl + '/addchannel', {
          params: {
            channel_id: channel_id,
            echip_id: chip_id,
            channel_name: name
          }
        })
          .then(response => {
            // console.log(response.data);
            if (response.data.status === 200 && response.data.message === "successful") {
              // console.log(response.data);
              this.setState({open: true, status: 'success'});

            } else if (response.data.status === 500) {
              // this.setState({open: true, status: 'duplicate'});
            }
          });
      } else {
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        if (channel_id !== 11 && name !== 'purple') {
          axios.get(config.apiUrl + '/remchannel', {
            params: {
              channel_id: channel_id,
              echip_id: chip_id,
            }
          }).then(response => {
            // console.log(response.data);
            if (response.data.status === 200) {
              this.setState({open: true, status: 'removed'})
            }
          })
        }
      }
      this.setState({selected: newSelected});
    }
  };

  captureLabel = (event, id, name) => {
    this.setState({
      editLabel: true,
      labelEdited: false,
      current_id: id,
      labelName: name
    })
  };

  changeLabelName = (event, id) => {
    this.setState({
      labelName: event.target.value,
      current_id: id,
    });
  };

  saveLabelName = (event, channel_id) => {
    event.preventDefault();
    axios.get(config.apiUrl + '/changechlabel', {
      params: {
        channel_id: channel_id,
        ch_name: this.state.labelName,
      }
    })
      .then(response => {
        if (response.data.status === 200) {
          axios.get(config.apiUrl + '/getchannels')
            .then(response => {
              const res = [];
              for (const val in response.data) {
                res.push(createData(response.data[val].channel_name, response.data[val].time, <span
                  className={response.data[val].channel_val}/>, response.data[val].channel_id, response.data[val].channel_val))
              }
              this.setState({
                data: res,
                editLabel: false,
                open: true,
                status: 'update'
              });
              this.setState(state => ({selected: state.data.map(n => n.id)}));
            })
        }
      });
  };

  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const {classes} = this.props;
    const {data, order, orderBy, selected, open, status, editLabel, current_id, labelName, rowsPerPage, page, indicators} = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div>
        <div className='row'>
          <span style={{marginLeft: 30, marginTop: 8, marginBottom: 5}}
                className='experiment-name'>{this.props.plates.postname}</span>
        </div>
        <Paper className={classes.root}>
          <EnhancedTableToolbar numSelected={selected.length}/>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <div>
                {
                  open && status === "success" &&
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={4000}
                    onClose={this.closeSnackBar}
                  >
                    <MySnackbarContentWrapper
                      onClose={this.closeSnackBar}
                      variant="success"
                      message="Channel added Successfully"
                    />
                  </Snackbar>
                }
                {
                  open && status === "update" &&
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={4000}
                    onClose={this.closeSnackBar}
                  >
                    <MySnackbarContentWrapper
                      onClose={this.closeSnackBar}
                      variant="success"
                      message="Label updated successfully"
                    />
                  </Snackbar>
                }
                {
                  open && status === "duplicate" &&
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={4000}
                    onClose={this.closeSnackBar}
                  >
                    <MySnackbarContentWrapper
                      variant="error"
                      className={classes.margin}
                      message="Channel already added to chip"
                    />
                  </Snackbar>}
                {
                  open && status === "removed" &&
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={4000}
                    onClose={this.closeSnackBar}
                  >
                    <MySnackbarContentWrapper
                      variant="info"
                      className={classes.margin}
                      message="Channel removed successfully"
                    />
                  </Snackbar>
                }
              </div>
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.id);
                    if (n.color === 'purple') {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.id}
                          selected={isSelected}
                          style={{backgroundColor: '#f5f5f5'}}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox color='primary' checked={true} disabled={true}/>
                          </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              Label-free
                            </TableCell>
                          <TableCell align="right">{n.time}</TableCell>
                          <TableCell align="right">{n.spacer}</TableCell>
                        </TableRow>
                      )
                    } else {
                      return (
                        <TableRow
                          hover
                          onClick={event => this.handleClick(event, n.id, n.channel_id, n.name, isSelected)}
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.id}
                          selected={isSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox color='primary' checked={isSelected}/>
                          </TableCell>
                          {editLabel && n.id === current_id ?
                            <TableCell component="th" scope="row" padding="none">
                              <form onSubmit={event => this.saveLabelName(event, n.channel_id)}>
                                <input type='text'
                                       className="form-control form-control-sm"
                                       onChange={event => this.changeLabelName(event, n.id)}
                                       value={labelName}
                                />
                              </form>
                            </TableCell> :
                            <TableCell component="th" scope="row" padding="none"  onClick={event => this.captureLabel(event, n.id, n.name)}>{n.name}</TableCell>
                          }
                          <TableCell align="right">{n.time}</TableCell>
                          <TableCell align="right">{n.spacer}</TableCell>
                        </TableRow>
                      )
                    }
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{height: 49 * emptyRows}}>
                    <TableCell colSpan={6}/>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    plates: state.plates
  }
}

KitTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles))(KitTable);
