import * as types from '../actions/actionTypes';

const initialState = {
  tip_name: 'none'
};

export default function showTips(state = initialState, action) {
  if (action.type === types.SHOW_TIP) {
    return {
      ...state,
      tip_name: action.tip_name
    };
  } else {
    return state;
  }
}
