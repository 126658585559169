import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import AnalyzeKits from './analyzeKits';
import AnalyzeChips from './analyzeChips';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as prescanActions from '../../actions/prescanActions';
import {bindActionCreators} from "redux";
import ExperimentTable from './experimentTable';
import AnalyzeChipDetails from './Analyzechipdetails';
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import axios from 'axios'
import config from '../../config.json'
//snackbar
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import green from '@material-ui/core/colors/green';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import amber from '@material-ui/core/colors/amber';
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  paper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    backgroundColor: "#e3f2fd",
  },
  navLink: {
    display: "block",
    color: "#757575",
    fontSize: "14px",
    textDecoration: "none",
  },
  emphasisChips: {
    textDecoration: 'underline',
    color: '#2980b9'
  },
  disperse: {
    width: "16px",
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});


const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
    fontSize: "13px",
    // backgroundColor: "#cae3f5"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const {children, classes, onClose} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="p">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 3,
    fontSize: "13px"
  }
}))(MuiDialogContent);

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};


function MySnackbarContent(props) {
  const {classes, className, message, onClose, variant, ...other} = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)}/>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon}/>
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

class Analyze extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      chip: [],
      data: null,
      chips: null,
      exptid: 0,
      analysis_id: 0,
      analysis_name: '',
      newExptName: '',
      status: '',
      open: false,
      chipCount: 0,
      openDialog: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handler = this.handler.bind(this);
    this.populateChips = this.populateChips.bind(this);
    this.chipDetails = this.chipDetails.bind(this);
  }


  handleClickOpen = () => {
    this.setState({
      openDialog: true
    })
  };

  handleClose = () => {
    this.setState({
      openDialog: false
    })
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open: false});
  };

  toggleMenu() {
    this.setState({menu: !this.state.menu})
  }

  changeName = event => {
    this.setState({
      newExptName: event.target.value
    })
  };

  componentDidMount() {
    if (this.props.plates.analysis_id !== 0) {
      axios.get(config.apiUrl + "/displayechips", {
        params: {
          exptid: this.props.plates.analysis_id
        }
      })
        .then(response => {
          // console.log(response.data);
          const res = response.data;

          const arr = [];
          for (var val in res) {
            arr.push(res[val].chip_name);
          }
          this.setState({
            chipCount: arr.length
          });
        });
    }
  }

  chipDetails(count) {
    console.log(count);

    axios.get(config.apiUrl + "/displayechips", {
      params: {
        exptid: this.props.plates.analysis_id
      }
    })
      .then(response => {
        const res = response.data;
        const arr = [];
        for (var val in res) {
          arr.push(res[val].chip_name);
        }
        // console.log(arr.length);
        this.setState({
          chipCount: arr.length
        });
      });

  }

  modifyExprName = event => {
    event.preventDefault();
    axios.get(config.apiUrl + '/modifyExperimentName', {
      params: {
        exptid: this.props.plates.analysis_id,
        newName: this.state.newExptName
      }
    })
      .then(response => {
        if (response.data.status === 200) {
          this.props.actions.analysisName(this.state.newExptName);
          this.setState({
            status: 'success',
            open: true
          });
        } else {
          alert('No changes made, try again.')
        }
      })
  };

  handler(exptid) {
    // console.log(exptid);
    axios.get(config.apiUrl + '/getePlates', {
      params: {
        exptid: exptid
      }
    })
      .then(response => {
        this.setState({
          data: response.data,
          exptid: exptid
        });
        this.props.actions.selectedExperiment(this.state.exptid);
      });
  }

  populateChips(plate_id) {
    axios.get(config.apiUrl + '/getPlatesById', {
      params: {
        plate_id: plate_id
      }
    }).then(response => {
      this.setState({
        chips: response.data.chips,
      })
    })
  }

  render() {
    const {classes} = this.props;
    const {chips, data, open, status, chipCount} = this.state;
    return (
      <div className={classes.root}>
        <div>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openDialog}
          >
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
              <div className='exo-gradient' style={{marginTop:6}}>
                <h6 style={{marginLeft: '45%'}} className='exo-name'>CHIPS</h6>
              </div>
            </DialogTitle>
            <DialogContent style={{width: "600px", padding: "20px"}}>
              <AnalyzeChipDetails/>
            </DialogContent>
          </Dialog>
        </div>
        <div>
          {
            open && status === 'success' &&
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
            >
              <MySnackbarContentWrapper
                onClose={this.closeSnackBar}
                variant="success"
                message="Name changed successfully!"
              />
            </Snackbar>
          }
        </div>
        <div className="row ml-2">
          <div className="col-md-4">
            <ExperimentTable handler={this.handler}/>
          </div>
          <div className="col-md-4">
            <AnalyzeKits populateChips={this.populateChips} data={data}/>
          </div>
          <div className="col-md-4">
            <AnalyzeChips exptid={this.props.plates.exptid} chips={chips} chipDetails={this.chipDetails}/>
          </div>
        </div>
        <div className="row ml-2 mt-3">
          <div className="col-md-8">
            <form className='container  ' onSubmit={event => this.modifyExprName(event)}>
              <div className="form-group row col-md-12">
                <div className="row col-md-12">
                  <label htmlFor="inputSearch" className="col-md-3 mt-1">Save Experiment as:</label>
                  <input type="text" className="form-control col-sm-6" id="inputSearch"
                         placeholder={this.props.plates.analysis_name}
                         onChange={event => this.changeName(event)}/>

                  <small className="col-md-3 mt-2">
                    <span className="chipCount" style={{marginLeft: 80}}>
                     <span onMouseEnter={this.handleClickOpen}>{chipCount === 0 ? "No" : chipCount} chips</span>
                    </span>
                  </small>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-4">
            <Link to='/analyze/analyzesummary'>
              <button className="exo-btn shadow select-file">View Analysis Summary</button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    plates: state.plates
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(prescanActions, dispatch)
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Analyze));
