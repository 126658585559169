import * as types from '../actions/actionTypes';

const initialState = {
  key: 0,
  name: "Prescan 2019-04-01 10:27:16",
  postname: "Postscan 2019-04-01 10:27:16",
  permanentName: 'Prescan 2019-04-01 10:27:16',
  kitId: [],
  postscan_id: 0,
  analysis_id: 0,
  prescanned: false,
  exptid: 0,
  analysis_name: 'Awesome Colab. Analysis'
};

export default function prescanReducer(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_EXPER:
      return {
        ...state,
        key: action.experiment
      };
    case types.EXPERIMENT_NAME:
      return {
        ...state,
        name: action.name
      };
    case types.POSTSCAN_NAME:
      return {
        ...state,
        postname: action.postname
      };
    case types.PERMANENT_NAME:
      return {
        ...state,
        permanentName: action.permanentName
      };
    case types.POSTSCAN_ID:
      return {
        ...state,
        postscan_id: action.postscan_id
      };
    case types.SELECTED_EXPT:
      return {
        ...state,
        exptid: action.exptid
      };
    case types.ANALYSIS_ID:
      return {
        ...state,
        analysis_id: action.analysis_id
      };
    case types.ANALYSIS_NAME:
      return {
        ...state,
        analysis_name: action.analysis_name
      };
    case types.PRESCANNED:
      return {
        ...state,
        prescanned: action.prescanned
      };
    case types.ANALYSIS_EXPERIMENT:
      return [...state,
        Object.assign({}, action.kitId)];
// case types.CREATE_SCAN://return [...state,//Object.assign({}, action.plate)];
    default:
      return state;
  }
}
