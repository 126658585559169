import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import {lighten} from '@material-ui/core/styles/colorManipulator';
import '../../index.css';
import red from '../../img/red.png';
import compose from 'recompose/compose'
import axios from 'axios';
import {connect} from 'react-redux';
import config from '../../config.json'
import mapDispatchToProps from "react-redux/es/connect/mapDispatchToProps";


let counter = 0;

function createData(kitType, kitId, manufdate, chip,  channels) {
  counter += 1;
  const arr = [];
  for (var color in channels) {
    arr.push(createColor(channels[color].channel_val))
  }
  return {id: counter, kitType, kitId, manufdate, chip,  color_array: arr};
}

let init = 0;

function createColor(colorName) {
  init += 1;
  return {id: init, color_name: colorName}
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {id: 'kitType', numeric: false, disablePadding: false, label: 'Kit Type'},
  {id: 'kitId', numeric: true, disablePadding: false, label: 'Kit ID'},
  {id: 'manufdate', numeric: true, disablePadding: false, label: 'Manufucturing Date'},
  {id: 'chip', numeric: true, disablePadding: false, label: 'Chip #'},
  {id: 'channels', numeric: true, disablePadding: false, label: 'Channels'}

];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {order, orderBy, rowCount} = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'default' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const {classes} = props;

  return (
    <Toolbar
    >
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Experiment Summary: {props.plates.name}
        </Typography>

      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = compose(connect(mapStateToProps), withStyles(toolbarStyles))(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },


  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      color: red[800],
    },
  },

});


class PrescanSummaryTable extends React.Component {

  state = {
    order: 'asc',
    orderBy: 'kitId',
    colorArr: [],
    data: [],
    page: 0,
    open: false,
    rowsPerPage: 5,
  };

  componentDidMount() {
    axios.get(config.apiUrl + '/displayechips', {
      params: {
        exptid: this.props.plates.key
      }
    })
      .then(response => {
        console.log(response.data);
        const res = response.data;

        const arr = [];
        for (var val in res) {
          arr.push(createData(res[val].kit_type, res[val].plate_name, `${this.props.plates.permanentName.split(" ")[1]}`,
            res[val].chip_name, res[val].channels))
        }
        this.setState({
          data: arr
        })
      })
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({open: false});
  };


  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({order, orderBy});
  };


  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const {classes} = this.props;
    const {data, order, orderBy, rowsPerPage, page} = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (


      <Paper className={classes.root}>

        <EnhancedTableToolbar/>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={n.id}
                    >
                      <TableCell component="th" scope="row" padding="default">
                        {n.kitType}
                      </TableCell>
                      <TableCell align="right">{n.kitId}</TableCell>
                      <TableCell align="right">{n.manufdate}</TableCell>
                      <TableCell align="right">{n.chip}</TableCell>
                      <TableCell align="right">{n.color_array.map(color => {
                        return (<span key={color.id} className={`${color.color_name}`} style={{marginRight: 1}}/>)
                      })}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{height: 49 * emptyRows}}>
                  <TableCell colSpan={6}/>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

PrescanSummaryTable.propTypes = {
  classes: PropTypes.object.isRequired,
};


function mapStateToProps(state, ownProps) {
  return {
    plates: state.plates
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles))(PrescanSummaryTable);
