import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import FileUploader, {FileManager} from 'reactjs-file-uploader';
import axios from 'axios';
import config from '../../config.json'
import {Link} from "react-router-dom";
// var XMLParser = require('react-xml-parser');
// const xmlText = require('./chipdata.xml');

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    flexWrap: 'wrap',

  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  paper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    backgroundColor: "#e3f2fd",
  },
  navLink: {
    display: "block",
    color: "#757575",
    fontSize: "14px",
    textDecoration: "none",
  }
});

let counter = 0;

function createData(chips, chiptype, plate_name) {
  counter += 1;
  return {id: counter, chiptype, plate_name, chips: chips};
}

class Chipfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      plate_name: '',
      chip_name: '',
      selectedFile: null,
      selectedFiles: null,
      fileName: 'No file chosen',
      status: false,
      created: false,
      data: null,
      enabled: false,
      succeeded: 0,
      failed: 0,
      numberImported: 0,
    }
  }

  fileSelectedHandler = event => {
    const arr = [];
    let target = event.target.files;
    for (let i = 0; i < target.length; i++) {
      arr.push(target[i].name);
    }
    this.setState({
      files: arr,
      numberImported: target.length,
      selectedFile: event.target.files,
      enabled: true
    })
  };

  restoreImport = event => {
    this.setState({
      status: false,
      created: true,
    })
  };

  fileUploadHandler = () => {
    const {selectedFile} = this.state;

    let successful = 0;
    let failed = 0;
    const formData = new FormData();

    for (let i = 0; i < selectedFile.length; i++) {
      formData.append('file', selectedFile[i]);
      axios.post(config.apiUrl + '/importchipsfromfile', formData, {
        onUploadProgress: progressEvent => {
          console.log('Upload Progress: ' + Math.round(progressEvent.loaded / progressEvent.total * 100) + '%');
        }
      })
        .then(response => {
            const res = response.data;
            for (let data in res) {
              if (res[data].status === 200) {
                successful += 1;
                this.setState({
                  status: true,
                  created: true,
                  succeeded: successful,
                });
              } else {
                failed += 1;
                this.setState({
                  status: true,
                  created: false,
                  failed: failed,
                });
              }
            }
          }
        )
    }
  };

  render() {
    const {classes} = this.props;
    const {status, created, numberImported, files, failed, succeeded, enabled} = this.state;
    const total = failed + succeeded;
    const percent = Math.round(succeeded / total * 100);

    return (
      <div style={{minHeight: 530}}>
        <div className={classes.root}>
          {status ?
            (<div className='container-fluid' style={{marginTop: 70, marginLeft:40}}>
                <h5>{percent}% of files imported correctly. <span
                  className='text-success'>{succeeded} imported correctly.</span> <span
                  className='text-danger'>{failed === 0 ? "None" : failed} failed.</span>
                </h5>
                <hr/>
                {created ? <p>You are now ready to prescan chips.</p> :
                  <div>
                    <p>These chips could have <span style={{fontWeight:'bold'}}>already been imported.</span></p>
                    <button
                      className=" btn exo-btn shadow-sm"
                      style={{padding: '6px 12px'}}
                      onClick={event => this.restoreImport(event)}>
                      Try Again
                    </button>
                  </div>
                }
              </div>
            ) :
            (<div>
              <div className='container-fluid' style={{marginLeft: 40, marginTop: 20}}>
                <input
                  type="file"
                  style={{display: 'none'}}
                  onChange={this.fileSelectedHandler}
                  ref={fileInput => this.fileInput = fileInput}
                  multiple
                />
                <button className="exo-btn-select shadow select-file" onClick={() => this.fileInput.click()}>Choose File
                </button>
                {enabled &&
                <button className="exo-btn shadow select-file" onClick={this.fileUploadHandler}>Upload File</button>}
                {numberImported > 0 && <div style={{marginTop: 25}}>
                  <p style={{fontSize: 14, textDecoration: "underline"}}>
                    You have imported {numberImported} file{numberImported > 1 ? "s." : "."}</p></div>}
                <div className='mt-3'>
                  {files.map(file => <span><span style={{fontSize: 14}}>{file}</span><br/></span>)}
                </div>
              </div>

              {numberImported === 0 &&
              <p className="text-center" style={{fontSize: 20}}>Select the provided chip files for your ExoView kit,
                select
                one chip file, <br/>all chip files from a folder will be imported</p>}
            </div>)
          }
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Chipfiles);
