import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import grey from '@material-ui/core/colors/grey';
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import axios from 'axios';
import config from "../../config.json";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 5,
  },
  paper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    backgroundColor: "#e3f2fd",
  },
  navLink:{
    display: "block",
    color: "#757575",
    fontSize: "14px",
    textDecoration: "none",
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
});

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
    paddingTop: theme.spacing.unit*2,
    paddingLeft:20,
    fontSize: "13px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const {children, classes, onClose} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="p">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 3,
    fontSize: "13px"
  }
}))(MuiDialogContent);

// const light  = grey[50];


 class Mainmenu extends Component {
   constructor(props){
     super();
     this.state = {
      openExperimentDialog:false,
      experiments:[]
     }
   }

   handleExperimentClickOpen = () => {
    this.setState({
      openExperimentDialog: true
    });
  };

  handleExperimentDialogClose = () => {
    this.setState({openExperimentDialog: false});
  };

  componentDidMount(){
    axios.get(config.apiUrl + '/getAnalysedExperiments')
    .then(response =>{
      console.log(response.data);
      this.setState({experiments:response.data})
    })
  }
  render() {
    const { classes } = this.props;
    const {openExperimentDialog,experiments} = this.state;
   
    return (



      <div className={classes.root}>
        <div>
          <Dialog
            onClose={this.handleExperimentDialogClose}
            aria-labelledby="customized-dialog-title"
            open={openExperimentDialog}
          >
            <DialogTitle id="customized-dialog-title" onClose={this.handleExperimentDialogClose} className="display-4">
              <h5>Experiments</h5>
            </DialogTitle>
            <DialogContent style={{width: "600px", padding: "20px"}}>
              <div className="row p-3">
                <div className="col-md-6">
                  <h6>Experiment</h6>
                </div>
                <div className="col-md-6">
                  <h6>Date Created</h6>
                </div>
              </div>
              {
                experiments.map(experiment =>{
                  return(
                    <div className="row p-3" key={experiment.id}>
                      <div className="col-md-6">
                        <Tooltip title="Click to view experiment details" classes={{ tooltip: classes.lightTooltip }}><a href="#" style={{color:"inherit"}}>{experiment.experiment_name}</a></Tooltip>
                      </div>
                      <div className="col-md-6">
                        <a href="#" style={{color:"inherit"}}>{experiment.created_on}</a>
                      </div>
                    </div>

                  )

                })
              }

            </DialogContent>
          </Dialog>
        </div>
        <p className="text-center" style={{fontSize: 20}}>Welcome to NanoViewer, control and analysis software for the <br/> NanoView Platform</p>
        <div className="advanced">
          <h4 className='ml-5'>Advanced</h4>
          <div className="card card-body rounded ">
            <span className="container">
              <Tooltip title='Disabled' classes={{ tooltip: classes.lightTooltip }}><button className="btn btn-light btn-experiment" >Create Experiment</button></Tooltip>
              <Tooltip title='Disabled' classes={{ tooltip: classes.lightTooltip }}><button className="btn btn-light btn-experiment" >Modify Experiment</button></Tooltip>
              <Tooltip title='Disabled' classes={{ tooltip: classes.lightTooltip }}><button className="btn btn-light btn-experiment" >Clone Experiment</button></Tooltip>
              <Tooltip title='Disabled' classes={{ tooltip: classes.lightTooltip }}><button className="btn btn-light btn-experiment" >Export Experiment</button></Tooltip>
              <Tooltip title="Click to view performed experiments" classes={{ tooltip: classes.lightTooltip }} style={{display:"none"}}><button color="inherit" className="btn btn-light btn-experiment" onClick={this.handleExperimentClickOpen}>Review Experiments</button></Tooltip>

            </span>
          </div>
        </div>
      </div>


    )
  }
}

Mainmenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Mainmenu);