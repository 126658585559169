import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles/index";
import Table from "@material-ui/core/Table/index";
import TableBody from "@material-ui/core/TableBody/index";
import TableCell from "@material-ui/core/TableCell/index";
import TableHead from "@material-ui/core/TableHead/index";
import TablePagination from "@material-ui/core/TablePagination/index";
import TableRow from "@material-ui/core/TableRow/index";
import TableSortLabel from "@material-ui/core/TableSortLabel/index";
import Toolbar from "@material-ui/core/Toolbar/index";
import Typography from "@material-ui/core/Typography/index";
import Paper from "@material-ui/core/Paper/index";
import Checkbox from "@material-ui/core/Checkbox/index";
import IconButton from "@material-ui/core/IconButton/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import NotesIcon from "@material-ui/icons/Notes";
import {lighten} from "@material-ui/core/styles/colorManipulator";
import "../../index.css";
import red from "../../img/red.png";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import CreateIcon from "@material-ui/icons/Create";
import compose from "recompose/compose";
import axios from "axios";
import {connect} from "react-redux";
import config from "../../config.json";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 3,
    fontSize: "13px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const {children, classes, onClose} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 3,
    fontSize: "13px"
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit,
    fontSize: "13px"
  }
}))(MuiDialogActions);

let counter = 0;

function createData(kitType, kitId, manufdate, chip, sampleName, sampleType, dilution, channels) {
  counter += 1;
  const arr = [];
  for (var color in channels) {
    arr.push(createColor(channels[color].channel_val));
  }
  return {
    id: counter,
    chip,
    kitType,
    kitId,
    manufdate,
    sampleName,
    sampleType,
    dilution,
    color_array: arr
  };
}

let init = 0;

function createColor(colorName) {
  init += 1;
  return {id: init, color_name: colorName};
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {id: "chip", numeric: true, disablePadding: false, label: "Chip Name"},
  {id: "kitType", numeric: true, disablePadding: false, label: "Kit Type"},
  {id: "kitId", numeric: true, disablePadding: false, label: "Kit ID"},
  {id: "manufdate", numeric: true, disablePadding: false, label: "Manufucturing Date"},
  {id: "sampleName", numeric: true, disablePadding: false, label: "Sample Name"},
  {id: "sampleType", numeric: true, disablePadding: false, label: "Sample Type"},
  {id: "dilution", numeric: true, disablePadding: false, label: "Dilution"},
  {id: "channels", numeric: true, disablePadding: false, label: "Channels"}
  // { id: 'notes', numeric: false, disablePadding: true, label: 'Notes' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {order, orderBy, rowCount} = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox"/>
          <TableCell padding="checkbox"/>

          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "default" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
});

let EnhancedTableToolbar = props => {
  const {classes} = props;

  return (
    <Toolbar>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Experiment Summary : {props.plates.analysis_name}
        </Typography>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = compose(
  connect(mapStateToProps),
  withStyles(toolbarStyles)
)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 1500
  },
  tableWrapper: {
    overflowX: "auto"
  },

  iconHover: {
    margin: theme.spacing.unit * 2,
    "&:hover": {
      color: red[800]
    }
  },
  notesButtonIcon: {
    visibility: "hidden"
  },
  experimentRow: {
    border: "1px solid #DCDCDC",
    "&:hover $notesButtonIcon": {
      visibility: "inherit"
    }
  }
});

class KitTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "kitId",
      colorArr: [],
      data: [],
      page: 0,
      open: false,
      rowsPerPage: 5,
      title: "",
      details: "",
      notes: [],
      openDialog: false,
      current_id: 0

    };
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleDetailsChange = this.handleDetailsChange.bind(this);
    this.saveExperimentNotes = this.saveExperimentNotes.bind(this);
  }

  handleTitleChange = event => {
    this.setState({
      title: event.target.value
    });
  };
  handleDetailsChange = event => {
    this.setState({
      details: event.target.value
    });
  };

  removeExperiment = (index) => {
    const newRows = this.state.data.slice(0, index).concat(this.state.data.slice(index + 1));
    axios.get(config.apiUrl + "/removeExperiment",
      {
        params: {
          exptid: this.props.plates.exptid
        }
      }).then(response => {
      // console.log(response.data)
      this.setState({
        openBar: true,
        data: newRows,
        openDialog: false
      })
    })
  };

  saveExperimentNotes = event => {
    event.preventDefault();
    axios
      .get(config.apiUrl + "/saveExperimentNotes", {
        params: {
          exptid: this.props.plates.exptid,
          title: this.state.title,
          details: this.state.details
        }
      })
      .then(response => {
        axios.get(config.apiUrl + "/getExperimentNotes", {
          params: {
            exptid: this.props.plates.exptid,
            title: this.state.title,
            details: this.state.details
          }
        }).then(response=>{
          this.setState({
            notes:response.data
          })
        });
        this.setState({
          title: "",
          details: "",
          // open: false
        });
      });
  };

  componentDidMount() {
    axios
      .get(config.apiUrl + "/displayechips", {
        params: {
          exptid: this.props.plates.analysis_id
        }
      })
      .then(response => {
        // console.log(response.data);
        const res = response.data;

        const arr = [];
        for (var val in res) {
          arr.push(
            createData(
              res[val].kit_type,
              res[val].plate_name,
              `${this.props.plates.permanentName.split(" ")[1]}`,
              res[val].chip_name,
              res[val].sample.sample_name,
              res[val].sample.type,
              res[val].sample.dilution,
              res[val].channels,
              ""
            )
          );
        }
        this.setState({
          data: arr
        });
      });
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = event => {
    event.preventDefault();
    this.setState({open: false});
  };

  handleClickOpenDialog = (event, exptid) => {
    console.log(exptid);
    this.setState({ openDialog: true, current_id: exptid });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({order, orderBy});
  };

  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const {classes} = this.props;
    const {data, order, orderBy, rowsPerPage, page, title, details, notes, index} = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          style={{width: "100%"}}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            <h6>Experiment Notes</h6>
          </DialogTitle>
          <DialogContent style={{width: "600px"}}>
            <table className="table table-striped" style={{marginTop: 20}}>
              <thead>
              <tr>
                <th>Date/Time</th>
                <th>Visible To</th>
                <th>Entered By</th>
                <th>Note</th>
              </tr>
              </thead>
              {notes.map(note => {
                return (
                  <tbody key={note.id}>
                  <td/>
                  <td/>
                  <td>{note.brief_description}</td>
                  <td>{note.details}</td>
                  </tbody>
                );
              })}
            </table>
            <form
              onSubmit={event => this.saveExperimentNotes(event)}
              style={{padding: "10px 30px"}}
            >
              <div className="form-group row">
                <label className="col-sm-3">Entered By</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    onChange={event => this.handleTitleChange(event)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3">Note Text</label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="4"
                    value={details}
                    onChange={event => this.handleDetailsChange(event)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4">Visible To</label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    All
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option2"
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Private
                  </label>
                </div>
              </div>
              <div className="offset-md-6 mt-2 " style={{fontSize: "13px"}}>
                <span>
                  <button type="submit" className="btn exo-btn shadow-sm mr-3 ">
                    Save
                  </button>
                  <button className="btn" onClick={this.handleClose}>
                    Cancel
                  </button>
                </span>
              </div>
            </form>
          </DialogContent>
        </Dialog>
        <div>
          <Dialog
            onClose={this.handleCloseDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.openDialog}
          >
            <DialogTitle id="customized-dialog-title" onClose={this.handleCloseDialog}>
                <h4>Delete Experiment</h4>
            </DialogTitle>
            <DialogContent style={{ width: "600px", padding: "20px" }}>
              <p style={{ fontSize:15 }}>
                Are you sure you want to delete this experiment?
              </p>
            </DialogContent>
            <DialogActions>
              <Button onClick={event => this.removeExperiment(event, current_id)} color="inherit">
                Yes
              </Button>
              <Button onClick={this.handleCloseDialog} color="inherit">
                No
              </Button>
            </DialogActions>
          </Dialog>
          </div>
        <EnhancedTableToolbar/>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, index) => {
                  return (
                    <TableRow tabIndex={-1} key={n.id} className={classes.experimentRow}>
                      <TableCell padding="checkbox">
                        <div className={classes.notesButtonIcon}>
                          <Tooltip title="Notes">
                            <IconButton
                              onClick={event => this.handleClickOpen(event)}
                              style={{backgroundColor: "white"}}
                            >
                              <NotesIcon style={{backgroundColor: "white"}}/>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <div className={classes.notesButtonIcon}>
                          <Tooltip title="Remove">
                            <IconButton onClick={event => {this.handleClickOpenDialog(event, n.experiment_id);}}>
                              <DeleteIcon/>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>

                      <TableCell align="right">{n.chip}</TableCell>
                      <TableCell align="right">{n.kitType}</TableCell>
                      {/* <TableCell align="right">{n.kitType}</TableCell> */}
                      <TableCell align="right">{n.kitId}</TableCell>
                      <TableCell align="right">{n.manufdate}</TableCell>
                      <TableCell align="right">{n.sampleName}</TableCell>
                      <TableCell align="right">{n.sampleType}</TableCell>
                      <TableCell align="right">{n.dilution}</TableCell>
                      <TableCell align="right">
                        {n.color_array.map(color => {
                          return (
                            <span
                              key={color.id}
                              className={`${color.color_name}`}
                              style={{marginRight: 1}}
                            />
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{height: 49 * emptyRows}}>
                  <TableCell colSpan={6}/>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

KitTable.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    plates: state.plates
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(KitTable);
