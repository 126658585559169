import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {bindActionCreators} from "redux";
import {lighten} from '@material-ui/core/styles/colorManipulator';
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import CheckCircleIcon from "@material-ui/core/SvgIcon/SvgIcon";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import config from '../../config.json'
//snackbar
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

import SnackbarContent from "@material-ui/core/SnackbarContent/SnackbarContent";
import compose from "recompose/compose";
import connect from "react-redux/es/connect/connect";
import * as prescanActions from "../../actions/prescanActions";

let counter = 0;

function createData(name, scans, polymerno, chip_id, plate_id) {
  counter += 1;
  return {id: counter, name, scans, polymerno, chip_id: chip_id, plate_id: plate_id};
}

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const {classes, className, message, onClose, variant, ...other} = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)}/>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon}/>
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {id: 'name', numeric: false, disablePadding: true, label: 'Chip name'},
  {id: 'scans', numeric: true, disablePadding: true, label: 'Scan Number'},
  {id: 'polymerno', numeric: true, disablePadding: false, label: 'Polymer Lot No.'},
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              color="primary"
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten("#8acff1", 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const {numSelected, classes} = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>

        <Typography variant="h6" id="tableTitle">
          Chips
        </Typography>

      </div>
      <div className={classes.spacer}/>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 5,
  },
  table: {
    minWidth: 100,
  },
  tableWrapper: {
    overflowX: 'auto',
  },

});

class ChipTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'name',
    selected: [],
    data: [
      createData('Select Kit to display chips'),
    ],
    status: '',
    page: 0,
    rowsPerPage: 5,
    experimentStatus: false,
    experimentCreated: true,
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open: false});
  };

  handleClick = (event, id, chip_id, isSelected) => {
    const {selected, experimentStatus, data} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    {
      if (!isSelected) {
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }

        if (this.props.plates.postscan_id === 0 && !experimentStatus) {
          this.setState({experimentCreated: false});
          axios.get(config.apiUrl + "/createExperiment/?status=postscan")
            .then(response => {
              const exptid = response.data["Experiment id"];
              const postname = response.data["Name"];
              axios.get(config.apiUrl + '/addechip', {
                params: {
                  chipid: chip_id,
                  exptid: exptid,
                }
              })
                .then(response => {
                  if (response.data.data.status === 200 && response.data.data.message === "Succesfully added") {
                    this.props.actions.postScanId(exptid);
                    this.props.actions.postScanName(postname);
                    let select = [];
                    if (this.props.plates.prescanned && data.length === 1) {
                      let len = 2 * data.length + newSelected[0];
                      select = select.concat(selected, len);
                    } else {
                      let len = 2 * data.length + newSelected[0];
                      select = select.concat(selected, len);
                    }

                    this.setState({
                      open: true,
                      selected: select,
                      status: 'success',
                      experimentStatus: true,
                      experimentCreated: true,
                    });

                    const chipDetails = this.props.chipDetails;
                    {
                      chipDetails(selected.length);
                    }

                  } else if (response.data.data.status === 500) {

                    this.setState({open: true, status: 'duplicate'});
                  }
                });
            });
        } else if (this.props.plates.postscan_id !== 0 && experimentStatus) {

          axios.get(config.apiUrl + '/addechip', {
            params: {
              chipid: chip_id,
              exptid: this.props.plates.postscan_id
            }
          })
            .then(response => {
              if (response.data.data.status === 200 && response.data.data.message === "Succesfully added") {
                this.setState({open: true, status: 'success'});
                const chipDetails = this.props.chipDetails;
                {
                  chipDetails(selected.length);
                }
              } else if (response.data.data.status === 500) {
                this.setState({open: true, status: 'duplicate'});
              }
            });
        } else {
          axios.get(config.apiUrl + '/addechip', {
            params: {
              chipid: chip_id,
              exptid: this.props.plates.postscan_id
            }
          })
            .then(response => {
              if (response.data.data.status === 200 && response.data.data.message === "Succesfully added") {

                this.setState({open: true, status: 'success'});
                const chipDetails = this.props.chipDetails;
                {
                  chipDetails(selected.length);
                }

              } else if (response.data.data.status === 500) {

                this.setState({open: true, status: 'duplicate'});
              }
            });
        }
        const chipDetails = this.props.chipDetails;
        {
          chipDetails(selected.length)
        }
      } else {
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        axios.get(config.apiUrl + '/remechip', {
          params: {
            chipid: chip_id,
            exptid: this.props.plates.postscan_id
          }
        }).then(response => {
          if (response.data.status === 200) {
            this.setState({open: true, status: 'removed'});
            const chipDetails = this.props.chipDetails;
            {
              chipDetails(selected.length - 3)
            }
          }
        })
      }
      this.setState({selected: newSelected});
    }
  };

  componentWillMount() {
    this.setState({
      selected: [],
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.chips != null) {
      this.setState({
        data: nextProps.chips.map(chp =>
          createData(chp.chip_name, chp.scans, chp.polymerlotno === null ? "Not assigned" : chp.polymerlotno, chp.chip_id, chp.plate_id)
        )
      });
    }
  }

  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const {classes} = this.props;
    const {data, order, open, orderBy, status, selected, experimentCreated, rowsPerPage, page} = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div>
        <div className='row'>
          <span style={{marginLeft: 30, marginTop: 8, marginBottom: 5}}
                className='experiment-name'>{this.props.plates.postname}</span>
        </div>
        <Paper className={classes.root}>
          <EnhancedTableToolbar numSelected={selected.length}/>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <div>
                {
                  open && status === "success" &&
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={4000}
                    onClose={this.closeSnackBar}
                  >
                    <MySnackbarContentWrapper
                      onClose={this.closeSnackBar}
                      variant="success"
                      message="Chip added to Postscan"
                    />
                  </Snackbar>
                }
                {
                  open && status === "duplicate" &&
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={4000}
                    onClose={this.closeSnackBar}
                  >
                    <MySnackbarContentWrapper
                      variant="error"
                      className={classes.margin}
                      message="Duplicate chip, try again."
                    />
                  </Snackbar>}
                {
                  open && status === "removed" &&
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={4000}
                    onClose={this.closeSnackBar}
                  >
                    <MySnackbarContentWrapper
                      variant="info"
                      className={classes.margin}
                      message="Chip removed from Postscan"
                    />
                  </Snackbar>
                }
              </div>
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        hover
                        onClick={event => experimentCreated && this.handleClick(event, n.id, n.chip_id, isSelected)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isSelected} color="primary"/>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {n.name}
                        </TableCell>
                        <TableCell align="right">
                          {n.scans}
                        </TableCell>
                        <TableCell align="right">
                          {n.polymerno}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{height: 49 * emptyRows}}>
                    <TableCell colSpan={2}/>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    plates: state.plates
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(prescanActions, dispatch)
  };
}

ChipTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles))(ChipTable);
