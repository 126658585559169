import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import KitTable from './kitSelected';
import ChipTable from './ChipSelected';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from 'axios';
import {bindActionCreators} from "redux";
import * as prescanActions from '../../actions/prescanActions';
import compose from 'recompose/compose'
import config from '../../config.json'
//snackbar
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import green from '@material-ui/core/colors/green';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import amber from '@material-ui/core/colors/amber';
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  paper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    backgroundColor: "#e3f2fd",
  },
  paper2: {
    padding: `${theme.spacing.unit * 2}px`,
  },
  navLink: {
    display: "block",
    color: "#757575",
    fontSize: "14px",
    textDecoration: "none",
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  emphasisChips: {
    textDecoration: 'underline',
    color: '#2980b9'
  },
  disperse: {
    width: "16px",
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function MySnackbarContent(props) {
  const {classes, className, message, onClose, variant, ...other} = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)}/>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon}/>
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);


class ScanSelected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      kits: [],
      chips: [],
      chip_id: '',
      status: '',
      open: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.navigate = this.navigate.bind(this);
    this.handler = this.handler.bind(this);
  }

  handler(chip_id) {
    console.log(chip_id);
    this.setState({
      chip_id: chip_id,
    })
  }

  componentDidMount() {
    axios.get(config.apiUrl + '/displayechips', {
      params: {
        exptid: this.props.plates.postscan_id,
      }
    }).then(response => {
      console.log(response.data);
      this.setState({
        chips: response.data,
      })
    })
  }

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open: false});
  };

  changeName = event => {
    this.setState({
      newExptName: event.target.value
    })
  };

  modifyExprName = event => {
    event.preventDefault();
    axios.get(config.apiUrl + '/modifyExperimentName', {
      params: {
        exptid: this.props.plates.postscan_id,
        newName: this.state.newExptName
      }
    })
      .then(response => {
        if (response.data.status === 200) {
          this.props.actions.postScanName(this.state.newExptName);
          this.setState({
            status: 'success',
            open: true
          });
        } else {
          alert('No changes made, try again.')
        }
      })
  };


  toggleMenu() {
    this.setState({menu: !this.state.menu})
  }

  navigate(link) {
    this.props.history.push(link);
  }

  render() {
    const {classes} = this.props;
    const {chips, chip_id, open, status} = this.state;

    return (
      <div className={classes.root}>
        <div className='ml-4' style={{marginTop: 25}}>
          <div className="row col-md-12">
            <div className="col-md-8">
              <KitTable chips={chips} handler={this.handler}/>
            </div>
            <div className="col-md-4">
              <ChipTable chip_id={chip_id}/>
            </div>
          </div>
        </div>
        <div>
          {
            open && status === 'success' &&
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
            >
              <MySnackbarContentWrapper
                onClose={this.closeSnackBar}
                variant="success"
                message="Name changed successfully!"
              />
            </Snackbar>
          }
        </div>
        <div className="row  mt-3 ml-4">
          <div className="col-md-8">
            <form className='container ' onSubmit={event => this.modifyExprName(event)}>
              <div className="form-group row col-md-12">
                <div className="row col-md-9">
                  <label htmlFor="inputSearch" className="col-md-4 mt-1 ml-4">Save Experiment as:</label>
                  <input type="text" className="form-control col-sm-6" id="inputSearch"
                         placeholder={this.props.plates.postname}
                         onChange={event => this.changeName(event)}/>
                </div>
                <span className={`col-md-2 mt-2`} style={{marginLeft: 100}}>
                  {/*<Link to='/sampleoptions' className={classes.emphasisChips}>4 chips</Link>*/}
                </span>
              </div>
            </form>
          </div>
          <div className="col-md-4">
            <Link to='/postscan/postsummary'>
              <button className="exo-btn shadow select-file" type="button"
                      style={{padding: '6px 12px'}}>Proceed to Summary<i class="fas fa-microscope ml-1"
                                                                         style={{fontSize: '13px'}}/>
              </button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

ScanSelected.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    plates: state.plates
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(prescanActions, dispatch)
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(withRouter(ScanSelected));
