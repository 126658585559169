import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import SummaryTable from "./analyzesum";
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import KitTable from "./AnalyzeSummary";
import { connect } from "react-redux";
import axios from "axios";
import compose from "recompose/compose";
import * as prescanActions from "../../actions/prescanActions";
import { bindActionCreators } from "redux";
import config from "../../config.json";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
//snackbar
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import green from "@material-ui/core/colors/green";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import amber from "@material-ui/core/colors/amber";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GetAppIcon from "@material-ui/icons/GetApp";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 3,
    fontSize: "13px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 3,
    fontSize: "13px"
  }
}))(MuiDialogContent);

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: "center",
    flexWrap: "wrap"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  paper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    backgroundColor: "#e3f2fd"
  },
  paper2: {
    padding: `${theme.spacing.unit * 2}px`
  },
  navLink: {
    display: "block",
    color: "#757575",
    fontSize: "14px",
    textDecoration: "none"
  },
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: "none"
  },
  emphasisChips: {
    textDecoration: "underline",
    color: "#2980b9"
  },
  disperse: {
    width: "16px"
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

class AnalyzeResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      status: "",
      open: false,
      openMenu: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  handleClickOpen = () => {
    this.setState({
      openMenu: true
    });
  };

  handleClose = () => {
    this.setState({ openMenu: false });
  };

  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }

  closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  changeName = event => {
    this.setState({
      newExptName: event.target.value
    });
  };

  downloadReport = () => {
    axios.get(config.apiUrl + "/");
  };

  modifyExprName = event => {
    event.preventDefault();
    axios
      .get(config.apiUrl + "/modifyExperimentName", {
        params: {
          exptid: this.props.plates.analysis_id,
          newName: this.state.newExptName
        }
      })
      .then(response => {
        if (response.data.status === 200) {
          this.props.actions.analysisName(this.state.newExptName);
          this.setState({
            status: "success",
            open: true
          });
        } else {
          alert("No changes made, try again.");
        }
      });
  };

  navigate(link) {
    this.props.history.push(link);
  }

  render() {
    const { classes } = this.props;
    const { open, status } = this.state;

    console.log(this.props.plates.exptid);
    return (
      <div className={classes.root} style={{ marginRight: 10 }}>
        <div className="row col-md-12 ml-5" style={{ marginTop: 20 }}>
          <span className="col-md-4" style={{ padding: "6px 12px" }}>
            Analysis Summary
          </span>
          <span className="col-md-4" />
          <span className="col-md-4">
            <Tooltip title="Download Report">
              <a
                className="btn exo-btn shadow-sm"
                href={config.apiUrl + "/experimentsummary?exptid=" + this.props.plates.analysis_id}
                download="report"
                target='_blank'
              >
                Download <GetAppIcon />
              </a>
            </Tooltip>
          </span>
        </div>
        <div className="ml-4">
          <div className="col-md-12">
            <KitTable />
          </div>
        </div>

        <div>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openMenu}
          >
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
              <h6>Experiment Report</h6>
            </DialogTitle>
            <DialogContent style={{ width: "600px" }} />
          </Dialog>
        </div>
        <div>
          {open && status === "success" && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
            >
              <MySnackbarContentWrapper
                onClose={this.closeSnackBar}
                variant="success"
                message="Name changed successfully!"
              />
            </Snackbar>
          )}
        </div>
        <div className="row  mt-3 ml-4">
          <div className="col-md-8">
            <form className="container " onSubmit={event => this.modifyExprName(event)}>
              <div className="form-group row col-md-12">
                <div className="row col-md-9">
                  <label htmlFor="inputSearch" className="col-md-4 mt-1 ml-4">
                    Save Experiment as:
                  </label>
                  <input
                    type="text"
                    className="form-control col-sm-6"
                    id="inputSearch"
                    placeholder={this.props.plates.analysis_name}
                    onChange={event => this.changeName(event)}
                  />
                </div>
                <span className={`col-md-2 mt-2`} style={{ marginLeft: 100 }} />
              </div>
            </form>
          </div>
          <div className="col-md-4">
            <Link to="/analyze/loadAnalysis" className="col-md-4">
              <button
                className=" btn exo-btn shadow-sm"
                style={{ padding: "6px 12px", marginLeft: 60 }}
              >
                Start Analysis
                <i class="fas fa-play-circle ml-1" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    plates: state.plates
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(prescanActions, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(AnalyzeResults);
