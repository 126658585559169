import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import NotesIcon from "@material-ui/icons/Notes";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import FormGroup from "@material-ui/core/FormGroup";
import axios from "axios";
import { connect } from "react-redux";
import config from "../../config.json";
import { FormControl } from "react-bootstrap";
import FilterResults from "react-filter-search";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import green from "@material-ui/core/colors/green";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import amber from "@material-ui/core/colors/amber";
import zIndex from "@material-ui/core/styles/zIndex";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 80
  },
  tableWrapper: {
    overflowX: "auto"
  },
  notesButtonIcon: {
    visibility: "hidden"
  },
  experimentRow: {
    border: "1px solid #DCDCDC",
    "&:hover $notesButtonIcon": {
      visibility: "inherit"
    }
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});
const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
    paddingTop: theme.spacing.unit*2,
    paddingLeft:20,
    fontSize: "13px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 3,
    fontSize: "13px"
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    // borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  }
}))(MuiDialogActions);

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

let counter = 0;

function createData(experiment, date, experiment_id) {
  counter += 1;
  return { id: counter, experiment, date, experiment_id: experiment_id };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {id: "experiment", numeric: true, disablePadding: false, label: "Experiment"},
  {id: "notes", numeric: false, disablePadding: true, label: ""},
  {id: "remove", numeric: false, disablePadding: true, label: ""},
  {id: "date", numeric: false, disablePadding: true, label: "Created On"}
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Experiments
        </Typography>
      </div>
      <div className={classes.spacer} />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

class ExperimentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "date",
      selected: [],
      data: [createData("Loading...", "")],
      experiment_id: "",
      experiment_name: "",
      term: "",
      loaded: false,
      page: 0,
      rowsPerPage: 5,
      open: false,
      title: "",
      details: "",
      savedNotes: false,
      notes: [],
      openBar: false,
      openDialog: false,
      current_id: 0
    };
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleDetailsChange = this.handleDetailsChange.bind(this);
    this.saveExperimentNotes = this.saveExperimentNotes.bind(this);
    this.removeExperiment = this.removeExperiment.bind(this);
  }

  closeSnackBar = reason => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openBar: false
    })
  };

  searchHandler = event => {
    event.preventDefault();
    this.setState({ term: event.target.value });
  };

  handleTitleChange = event => {
    this.setState({
      title: event.target.value
    });
  };

  removeExperiment = (event, exptid) => {
    // const newRows = this.state.data.slice(0, index).concat(this.state.data.slice(index + 1));
    axios
      .get(config.apiUrl + "/removeExperiment", {
        params: {
          exptid: exptid
        }
      })
      .then(response => {
        // console.log(response.data)
        if (response.data.code === 200) {
          axios.get(config.apiUrl + "/getAnalysedExperiments").then(response => {
            const exptdata = response.data;
            const arr = [];
            for (var expt in exptdata) {
              if (exptdata[expt].created_on != null) {
                arr.push(
                  createData(
                    exptdata[expt].experiment_name,
                    exptdata[expt].created_on,
                    exptdata[expt].experiment_id
                  )
                );
              }
            }
            this.setState({
              data: arr
            });
          });
          this.setState({

            openBar: true,
            openDialog: false
          });
        }else if(response.data.code ===500){
          this.setState({
            openDialog: false
          });
        }
      });
  };

  handleDetailsChange = event => {
    this.setState({
      details: event.target.value
    });
  };

  saveExperimentNotes = event => {
    event.preventDefault();
    const { title, details } = this.state;
    if (title !== "" && details !== "") {
      axios
        .get(config.apiUrl + "/saveExperimentNotes", {
          params: {
            exptid: this.props.plates.exptid,
            title: this.state.title,
            details: this.state.details
          }
        })
        .then(response => {
          axios
            .get(config.apiUrl + "/getExperimentNotes", {
              params: {
                exptid: this.props.plates.exptid
              }
            })
            .then(response => {
              this.setState({
                notes: response.data,
                savedNotes: true
              });
            });
        });
    }
    this.setState({
      title: "",
      details: ""
    });
  };

  handleClickOpen = (event, exptid) => {
    // console.log(exptid);
    axios
      .get(config.apiUrl + "/getExperimentNotes", {
        params: {
          exptid: exptid
        }
      })
      .then(response => {
        console.log(response.data);
        if (response.data !== null) {
          this.setState({
            notes: response.data,
            savedNotes: true
          });
        } else {
          this.setState({
            notes: [],
            savedNotes: false
          });
        }
      });
    this.setState({
      open: true
    });
    const handler = this.props.handler;
    {
      handler(experiment_id);
    }
  };

  handleClose = () => {
    this.setState({ open: false, savedNotes: false });
  };
  handleClickOpenDialog = (event, exptid) => {
    console.log(exptid);
    this.setState({ openDialog: true, current_id: exptid });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  componentWillMount() {
    axios.get(config.apiUrl + "/getAnalysedExperiments").then(response => {
      const exptdata = response.data;
      const arr = [];
      for (var expt in exptdata) {
        if (exptdata[expt].created_on != null) {
          arr.push(
            createData(
              exptdata[expt].experiment_name,
              exptdata[expt].created_on,
              exptdata[expt].experiment_id
            )
          );
        }
      }
      this.setState({
        data: arr
      });
    });
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handlePlates = (event, experiment_id) => {
    const handler = this.props.handler;
    {
      handler(experiment_id);
    }
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const {
      data,
      order,
      orderBy,
      selected,
      term,
      rowsPerPage,
      page,
      title,
      details,
      notes,
      openBar,
      savedNotes,
      current_id
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div>
        <div className="row">
          <FormControl
            type="text"
            placeholder="Search"
            className="col-md-9"
            style={{ marginLeft: 20, marginTop: 20 }}
            onChange={event => this.searchHandler(event)}
            value={term}
          />
        </div>
        <Paper className={classes.root} style={{ fontSize: "13px" }}>
          <div>
            <Dialog
              onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              open={this.state.open}
            >
              <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                <h6>Experiment Notes</h6>
              </DialogTitle>
              <DialogContent style={{ width: "600px" }}>
                <div>
                  {savedNotes && (
                    <table className="table" style={{ marginTop: 20 }}>
                      <thead>
                        <tr>
                          <th>Date/Time</th>
                          <th>Visible To</th>
                          <th>Performed By</th>
                          <th>Note</th>
                        </tr>
                      </thead>
                      {notes.map(note => {
                        return (
                          <tbody key={note.id}>
                            <td />
                            <td />
                            <td>{note.brief_description}</td>
                            <td>{note.details}</td>
                          </tbody>
                        );
                      })}
                    </table>
                  )}
                  <form
                    onSubmit={event => this.saveExperimentNotes(event)}
                    style={{ marginTop: 40, padding: "10px 30px" }}
                  >
                    <div className="form-group row">
                      <label className="col-sm-3">Performed By</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          value={title}
                          onChange={event => this.handleTitleChange(event)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3">Note Text</label>
                      <div className="col-sm-9">
                        <textarea
                          className="form-control"
                          rows="4"
                          value={details}
                          onChange={event => this.handleDetailsChange(event)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3">Visible To</label>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          All
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="option2"
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          Private
                        </label>
                      </div>
                    </div>
                    <div className="offset-md-6 mt-2 ">
                      <span>
                        <button type="submit" className="btn exo-btn shadow-sm mr-3 ">
                          Save
                        </button>
                        <button className="btn" onClick={this.handleClose}>
                          Cancel
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </DialogContent>
            </Dialog>
          </div>
          <div>
            <Dialog
              onClose={this.handleCloseDialog}
              aria-labelledby="customized-dialog-title"
              open={this.state.openDialog}
            >
              <DialogTitle id="customized-dialog-title" onClose={this.handleCloseDialog}>
                  <h4>Delete Experiment</h4>
              </DialogTitle>
              <DialogContent style={{ width: "600px", padding: "20px" }}>
                <p style={{ fontSize:15 }}>
                  Are you sure you want to delete this experiment?
                </p>
              </DialogContent>
              <DialogActions>
                <Button onClick={event => this.removeExperiment(event, current_id)} color="inherit">
                  Yes
                </Button>
                <Button onClick={this.handleCloseDialog} color="inherit">
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          {open && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              open={openBar}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
            >
              <MySnackbarContentWrapper
                onClose={this.closeSnackBar}
                variant="success"
                message="Experiment deleted successfully!"
              />
            </Snackbar>
          )}
          <EnhancedTableToolbar numSelected={selected.length} />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <FilterResults
                value={term}
                data={data}
                renderResults={results => (
                  <TableBody>
                    {stableSort(results, getSorting(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((n, index) => {
                        const isSelected = this.isSelected(n.id);
                        return (
                          <TableRow
                            onClick={event => {
                              this.handleClick(event, n.id);
                              this.handlePlates(event, n.experiment_id);
                            }}
                            role="checkbox"
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={n.id}
                            selected={isSelected}
                            className={classes.experimentRow}
                          >
                            <TableCell component="th" scope="row" padding="checkbox" style={{paddingLeft: 20}}>
                              {n.experiment}
                            </TableCell>
                            <TableCell padding="none">
                              <div className={classes.notesButtonIcon}>
                                <Tooltip title="Notes">
                                  <IconButton
                                    onClick={event => {
                                      this.handleClickOpen(event, n.experiment_id);
                                    }}
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <NotesIcon style={{ backgroundColor: "white" }} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                            <TableCell padding="none" style={{paddingRight: 20}}>
                              <div className={classes.notesButtonIcon}>
                                <Tooltip title="Remove">
                                  <IconButton
                                    style={{ backgroundColor: "white" }}
                                    onClick={event => {
                                      this.handleClickOpenDialog(event, n.experiment_id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                            <TableCell padding="none">{n.date}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}
              />
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

ExperimentTable.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    plates: state.plates
  };
}

export default connect(mapStateToProps)(withStyles(styles)(ExperimentTable));
