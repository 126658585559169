import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import {lighten} from '@material-ui/core/styles/colorManipulator';
import FormControl from '@material-ui/core/FormControl';
import CreateIcon from '@material-ui/icons/Create'
import Select from '@material-ui/core/Select';
import {connect} from "react-redux";
import compose from 'recompose/compose';
import axios from 'axios';
import config from '../../config.json'
import FilterResults from "react-filter-search";

let counter = 0;

function createData(kitId, chip, sampleName, sampleType, incubationTime, dilution, sample_id) {
  counter += 1;
  return {id: counter, kitId, chip, sampleName, sampleType, incubationTime, dilution, sample_id: sample_id};
}


const rows = [
  {id: 'kitId', numeric: false, disablePadding: true, label: 'Kit ID'},
  {id: 'chip', numeric: true, disablePadding: false, label: 'Chip Number'},
  {
    id: 'sampleName',
    numeric: true,
    disablePadding: false,
    label: <span><Typography className="text-muted" style={{fontSize: "12px"}}>Sample Name<span>
      <Tooltip
        title="Edit sample name"
        placement="bottom">
      <CreateIcon style={{fontSize: "17px", marginBottom: 4}} className='ml-1'/></Tooltip></span></Typography></span>
  },
  {id: 'sampleType', numeric: false, disablePadding: false, label: 'Sample Type'},
  {
    id: 'incubationTime',
    numeric: true,
    disablePadding: false,
    label: <span><Typography className="text-muted" style={{fontSize: "12px"}}>Incubation Time (hrs)<span><Tooltip
      title="Edit incubation time"
      placement="bottom"><CreateIcon
      style={{fontSize: "17px", marginBottom: 4}} className='ml-1'/></Tooltip></span></Typography></span>
  },
  {
    id: 'dilution',
    numeric: true,
    disablePadding: false,
    label: <span><Typography className="text-muted" style={{fontSize: "12px"}}>Dilution<span><Tooltip
      title="Edit Dilution"
      placement="bottom"><CreateIcon
      style={{fontSize: "17px", marginBottom: 4}} className='ml-1'/></Tooltip></span></Typography></span>
  },

];

class EnhancedTableHead extends React.Component {
  render() {
    const {onSelectAllClick, numSelected, rowCount} = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              color='primary'
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
              >
                {row.label}
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const {numSelected, classes} = props;

  return (
    <Toolbar
    >
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Chips
        </Typography>
      </div>
      <div className={classes.spacer}/>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 5,
  },
  table: {
    minWidth: 1100,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  selectroot: {
    display: 'flex',
    flexWrap: 'wrap',

  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 100,
    fontSize: '14px',

  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  postscanDescription: {
    border: "1px solid #DCDCDC",
    padding: 10,
    borderRadius: 6,
    fontSize: 13
  },
  postscanName: {
    "&:hover $postscanDescription": {
      visibility: "inherit",
    },
    marginTop: -10,
    marginBottom: -30
  }
});

class KitTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 5,
      value: 'Blood',
      editing: false,
      anchorEl: null,
      sample_id: '',
      sampleType: 'Cyst fluid',
      sampleName: '',
      dilution: '',
      current_id: '',
      editSample: false,
      incubationTime: '',
      incubation_id: '',
      term: '',
      dilution_id: '',
      editIncubation: false,
      editDilution: false,
    };
    this.changeSampleName = this.changeSampleName.bind(this);
    this.changeSampleType = this.changeSampleType.bind(this);
    this.changeIncubationTime = this.changeIncubationTime.bind(this);
    this.saveSampleName = this.saveSampleName.bind(this);
    this.captureSample = this.captureSample.bind(this);
    this.captureIncubationTime = this.captureIncubationTime.bind(this);
  }

  searchHandler = (event) => {
    event.preventDefault();
    this.setState({term: event.target.value})
  };

  //SAMPLE
  captureSample = (event, id, sample_name) => {
    this.setState({
      editSample: true,
      sampleEdited: false,
      current_id: id,
    })
  };

  changeSampleName = (event, id) => {
    this.setState({
      sampleName: event.target.value,
      current_id: id,
    });
  };

  saveSampleName = (event, sample_id) => {
    event.preventDefault();
    this.setState({
      sampleEdited: true,
    });
    axios.get(config.apiUrl + '/updateSampleNameById', {
      params: {
        sample_id: sample_id,
        sample_name: this.state.sampleName,
      }
    })
      .then(response => {
        axios.get(config.apiUrl + '/displayechips', {
          params: {
            exptid: this.props.plates.postscan_id,
          }
        }).then(response => {
          var data = [];
          for (var val in response.data) {
            data.push(createData(response.data[val].plate_name, response.data[val].exptchip_id,
              response.data[val].sample.sample_name, response.data[val].sample.type,
              response.data[val].sample.incubation_time, response.data[val].sample.dilution, response.data[val].sample.sample_id));
            sample_id = response.data[val].sample.sample_id;
          }
          this.setState({
            data: data,
            sample_id: sample_id,
            sampleName: "",
          })
        })
      })
  };

  //incubation time
  captureIncubationTime = (event, id, incubationTime) => {
    this.setState({
      editIncubation: true,
      incubationTime: incubationTime,
      incubation_id: id,
    })
  };

  changeIncubationTime = (event, id) => {
    this.setState({
      incubationTime: event.target.value,
      incubation_id: id
    });
  };

  saveIncubationTime = (event, sample_id) => {
    event.preventDefault();
    axios.get(config.apiUrl + '/updateIncubationTimeBySampleId', {
      params: {
        sample_id: sample_id,
        incubation_time: this.state.incubationTime
      }
    })
      .then(response => {
        axios.get(config.apiUrl + '/displayechips', {
          params: {
            exptid: this.props.plates.postscan_id,
          }
        }).then(response => {
          var data = [];
          for (var val in response.data) {
            data.push(createData(response.data[val].plate_name, response.data[val].exptchip_id, response.data[val].sample.sample_name,
              response.data[val].sample.type, response.data[val].sample.incubation_time, response.data[val].sample.dilution, response.data[val].sample.sample_id));
            sample_id = response.data[val].sample.sample_id;
          }
          this.setState({
            data: data,
            sample_id: sample_id
          })
        })
      })
  };

  //sample-type
  changeSampleType = (event, sample_id) => {
    axios.get(config.apiUrl + '/updateSampleTypeBySampleId', {
      params: {
        sample_id: sample_id,
        type: event.target.value
      }
    })
      .then(response => {
      })
  };

  captureDilution = (event, id, dilution) => {
    this.setState({
      editDilution: true,
      dilution: dilution,
      dilution_id: id,
    })
  };

  changeDilution = (event, id) => {
    this.setState({
      dilution: event.target.value,
      dilution_id: id
    });
  };

  saveDilution = (event, sample_id) => {
    event.preventDefault();
    axios.get(config.apiUrl + '/updateDilutionBySampleId', {
      params: {
        sample_id: sample_id,
        dilution: this.state.dilution
      }
    })
      .then(response => {
        axios.get(config.apiUrl + '/displayechips', {
          params: {
            exptid: this.props.plates.postscan_id,
          }
        }).then(response => {
          var data = [];
          for (var val in response.data) {
            data.push(createData(response.data[val].plate_name, response.data[val].chip_name, response.data[val].sample.sample_name,
              response.data[val].sample.type, response.data[val].sample.incubation_time, response.data[val].sample.dilution, response.data[val].sample.sample_id));
            sample_id = response.data[val].sample.sample_id;
          }
          this.setState({
            data: data,
            sample_id: sample_id
          })
        })
      })
  };

  componentWillReceiveProps(nextProps) {
    var data = [];
    let sample_id = '';
    for (var val in nextProps.chips) {
      data.push(createData(nextProps.chips[val].plate_name, nextProps.chips[val].chip_name, nextProps.chips[val].sample.sample_name,
        nextProps.chips[val].sample.type, nextProps.chips[val].sample.incubation_time, nextProps.chips[val].sample.dilution, nextProps.chips[val].sample.sample_id))
      sample_id = nextProps.chips[val].sample.sample_id;
    }

    this.setState({
      data: data,
      sample_id: sample_id
    })
  }

  handleChange = name => event => {
    this.setState({[name]: event.target.value});
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({selected: state.data.map(n => n.id)}));
      return;
    }
    this.setState({selected: []});
  };

  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };

  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };

  editField = () => {
    this.setState({
      editing: true,
    });
  };

  handleMenu = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      value: 'Saliva'
    });
  };

  handleChips = (event, exptchip_id) => {
    const handler = this.props.handler;
    const {selected} = this.state;
    {
      selected && handler(exptchip_id)
    }
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const {classes} = this.props;
    const {
      data, selected, editSample, sampleName, term, dilution, incubation_id, dilution_id,
      current_id, editIncubation, editDilution, incubationTime, rowsPerPage, page
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div>
        <div className='row' style={{marginLeft: 20}}>
          <div className="col-md-6">
            <input type="text"
                   placeholder='Search'
                   className='form-control col-md-10'
                   onChange={event => this.searchHandler(event)}
                   value={term}
            />
          </div>
          <div className="col-md-2 " className={classes.postscanName}>
            {this.props.tips.tip_name === 'prescan' &&
            <p className={classes.postscanDescription}>The baseline scan of a chip normally <br/>carried out with the
              violet channel</p>}
            {this.props.tips.tip_name === 'postscan' &&
            <p className={classes.postscanDescription}>Secondary scan of a chip normally carried out<br/> with the
              violet, red, green and blue channels</p>}
            {this.props.tips.tip_name === 'analyze' &&
            <p className={classes.postscanDescription}>Click here to view Pre and Post Scan<br/> elements and to combine
              experiments</p>}
          </div>
        </div>
        <Paper className={classes.root}>
          <EnhancedTableToolbar numSelected={selected.length}/>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={this.handleSelectAllClick}
                rowCount={data.length}
              />
              <FilterResults
                value={term}
                data={data}
                renderResults={results => (
                  <TableBody>
                    {results
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(n => {
                        const isSelected = this.isSelected(n.id);
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={n.id}
                          >
                            <TableCell padding="checkbox" aria-checked={isSelected} onClick={event => {
                              this.handleChips(event, n.chip);
                              this.handleClick(event, n.id)
                            }} selected={isSelected}>
                              <Checkbox checked={isSelected} color='primary'/>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              {n.kitId}
                            </TableCell>
                            <TableCell align="right">{n.chip}</TableCell>
                            {
                              editSample && n.id === current_id ?
                                <TableCell align="right">
                                  <form onSubmit={event => this.saveSampleName(event, n.sample_id)}>
                                    <input type='text'
                                           className="form-control form-control-sm"
                                           onChange={event => this.changeSampleName(event, n.id)}
                                           value={sampleName}
                                    />
                                  </form>
                                </TableCell>
                                :
                                <TableCell align="right"
                                           onClick={event => this.captureSample(event, n.id, n.sampleName)}>{n.sampleName}</TableCell>
                            }
                            <TableCell align="right">{
                              <FormControl className={classes.selectroot}>
                                <select
                                  onChange={event => this.changeSampleType(event, n.sample_id)}
                                  className={classes.formControl}
                                >
                                  <option>Plasma</option>
                                  <option>Serum</option>
                                  <option>Whole Blood</option>
                                  <option>Saliva</option>
                                  <option>{n.sampleType}</option>
                                  <option>Urine</option>
                                  <option>Gastric lavage</option>
                                  <option>CC Purified</option>
                                  <option>CC Unpurified</option>
                                  <option>Bronchial</option>
                                  <option>Alveolar</option>
                                  <option>Lavage</option>
                                  <option>Other</option>
                                </select>
                              </FormControl>

                            }</TableCell>
                            {
                              editIncubation && incubation_id === n.id ?
                                <TableCell align="right"
                                >
                                  <form onSubmit={event => this.saveIncubationTime(event, n.sample_id)}>
                                    <input type='text'
                                           className="form-control form-control-sm"
                                           onChange={event => this.changeIncubationTime(event, n.id)}
                                           value={incubationTime}/>
                                  </form>
                                </TableCell> :
                                <TableCell align="center"
                                           onClick={event => this.captureIncubationTime(event, n.id, n.incubationTime)}>
                                  {n.incubationTime}</TableCell>
                            }

                            {
                              editDilution && n.id === dilution_id ?
                                <TableCell align="right"
                                >
                                  <form onSubmit={event => this.saveDilution(event, n.sample_id)}>
                                    <input type='text'
                                           className="form-control form-control-sm"
                                           onChange={event => this.changeDilution(event, n.id)}
                                           value={dilution}/>
                                  </form>
                                </TableCell>
                                :
                                <TableCell align="right"
                                           onClick={event => this.captureDilution(event, n.id, n.dilution)}>{n.dilution}</TableCell>
                            }

                          </TableRow>
                        );
                        p
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{height: 49 * emptyRows}}>
                        <TableCell colSpan={6}/>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              />
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

KitTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    plates: state.plates,
    tips: state.tips,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(KitTable);
