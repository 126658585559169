import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import KitTable from "./kitResults";
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: "center",
    flexWrap: "wrap"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  paper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    backgroundColor: "#e3f2fd"
  },
  paper2: {
    padding: `${theme.spacing.unit * 2}px`
  },
  navLink: {
    display: "block",
    color: "#757575",
    fontSize: "14px",
    textDecoration: "none"
  },
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: "none"
  }
});

class Sample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }

  navigate(link) {
    this.props.history.push(link);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className="container">
          <div className="row col-md-12">
            <nav className="col-md-6">
              <Breadcrumbs
                aria-label="Breadcrumb"
                className="exo-gradient"
                style={{ marginTop: 20 }}
              >
                <Link
                  color="inherit"
                  to="/sampleoptions"
                  style={{ color: "#555", textDecoration: "none" }}
                >
                  <span className="btn text-info">Sample Options</span>
                </Link>
                <Link color="inherit" to="/scan" style={{ color: "#555", textDecoration: "none" }}>
                  <span className="btn text-secondary">Scan Selected</span>
                </Link>
              </Breadcrumbs>
            </nav>
            <Link to="/sample" className="col-md-6">
              <button
                className="btn exo-btn"
                type="button"
                style={{ marginTop: 20, padding: "6px 12px" }}
              >
                Start Scan <i class="fas fa-microscope ml-1" style={{ fontSize: "13px" }} />
              </button>
            </Link>
          </div>
        </div>
        <div className="container">
          <div className="col-md-12">
            <KitTable />
          </div>
        </div>
        <form className="container mt-3">
          <div className="form-group row col-sm-12">
            <div className="row col-sm-9">
              <label htmlFor="inputSearch" className="col-sm-3 mt-1 ml-4">
                Save Experiment as:
              </label>
              <input
                type="text"
                className="form-control col-sm-6"
                id="inputSearch"
                placeholder="Postscan 2019-03-25 10:47"
              />
            </div>
            <span className={`col-sm-2 mt-2`} style={{ marginLeft: 100 }}>
              <Link to="/sampleoptions" className={classes.emphasisChips}>
                4 chips
              </Link>
            </span>
          </div>
        </form>
      </div>
    );
  }
}
export default withStyles(styles)(Sample);
