import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';
import Mainmenu from './Main/mainMenu';
import Chipfiles from './Prescan/chipFiles';
import Selectchips from './Prescan/prescan';
import PostScan from './Postscan/Postscanchips';
import Sample from './Postscan/sampleinfo';
import SampleOptions from './Postscan/sampleOptions';
import ScanSelected from './Postscan/scanselected';
import LoadScreen from './Prescan/loadScreen';
import PrescanSummary from './Prescan/Summary'
import Analyze from './Analyze/Analyze';
import AnalyzeResults from './Analyze/analyzeresults'
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import logo from '../logo.png';
import {NavLink} from 'react-router-dom';
import PostSummary from './Postscan/PostSummary';
import '../index.css';
import axios from 'axios';
import LoadAnalysis from './Analyze/loadAnalysis'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import config from '../config.json'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {bindActionCreators} from "redux";
import * as prescanActions from "../actions/prescanActions";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";

//snackbar
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import green from '@material-ui/core/colors/green';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import amber from '@material-ui/core/colors/amber';
import Tooltip from '@material-ui/core/Tooltip';
import compose from "recompose/compose";
import {connect} from "react-redux";


const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  root: {
    // flexGrow: 1,
    justifyContent: 'center',
    // flexWrap: 'wrap',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  paper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    // backgroundColor: "#d7eaf8",
  },
  paper2: {
    padding: `${theme.spacing.unit * 2}px`,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  emphasisChips: {
    textDecoration: 'underline',
    color: '#2980b9'
  },
  disperse: {
    width: "16px",
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
});

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: 20,
    fontSize: "13px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const {children, classes, onClose} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="p">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 3,
    fontSize: "13px"
  }
}))(MuiDialogContent);

function MySnackbarContent(props) {
  const {classes, className, message, onClose, variant, ...other} = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)}/>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon}/>
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

const ITEM_HEIGHT = 48;


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      dbStatus: false,
      open: false,
      anchorEl: null,
      openDialog: false,

    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  handleClickOpen = () => {
    this.setState({
      openDialog: true
    });
  };

  handleDialogClose = () => {
    this.setState({openDialog: false});
  };


  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open: false});
  };

  toggleMenu() {
    this.setState({menu: !this.state.menu})
  }

  adaptTip = (event, tip) => {
    this.props.actions.saveTip(tip);
  };


  componentWillMount() {
    axios.get(config.apiUrl + '/dbstatus')
      .then(response => {
        if (response.data.Status === 200) {
          this.setState({
            dbStatus: !this.state.dbStatus,
            open: true,
          });
        }
      }).catch(error => {
      console.log(error);
    })
  }

  render() {
    const {classes} = this.props;
    const {dbStatus, anchorEl, openDialog} = this.state;
    const openmenu = Boolean(anchorEl);

    // console.log(this.props.tips.tip_name);
    return (
      <div className={classes.root}>
        <Router>
          <div>
            <div>
              <Dialog
                onClose={this.handleDialogClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
              >
                <DialogTitle id="customized-dialog-title" onClose={this.handleDialogClose} className="display-4">
                  <h5>About ExoView</h5>
                </DialogTitle>
                <DialogContent style={{width: "600px", padding: "20px"}}>
                  <p style={{fontSize: 15}}>ExoView v3.0.12</p>

                  {/*<p style={{fontSize: 11}} className='text-center'>ExoView&copy; 2019</p>*/}
                  <hr/>
                  <footer>
                    <small className='row col-md-12'>
                      <span className='col-md-3'></span>
                      <span className='col-md-3'>ExoView &copy; 2019</span>
                      <span className='col-md-6'>
                        <div>
                        <h4 className='mr-2 p-1'
                            style={{float: "left", border: "2px solid black"}}>RUO</h4>
                FOR RESEARCH USE ONLY. <br/>
               NOT FOR USE IN DIAGNOSTIC PROCEDURES</div></span>
                    </small>
                  </footer>
                </DialogContent>
              </Dialog>
            </div>

            <AppBar position="static" color="inherit">
              <Toolbar>
                <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                  <MenuIcon/>
                </IconButton>

                <Typography variant="h6" color="inherit" className={classes.grow}>
                  <div style={{textDecoration: 'none'}}>
                    <NavLink to='/' className='exo-link'>
                      <span>
                        <img src={logo} style={{width: 30}} alt='logo'/>  </span><span className='exo-name'><span
                      style={{color: '#2980b9'}}>Exo</span><span style={{color: '#30336b'}}>View</span>
                      </span>
                    </NavLink>
                  </div>
                </Typography>
                <div>
                  <Tooltip title="Options" placement="bottom"><IconButton
                    aria-label="More"
                    aria-owns={openmenu ? 'long-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    style={{marginLeft: '40%',}}
                  >
                    <MoreVertIcon/>
                  </IconButton></Tooltip>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={openmenu}
                    onClose={this.handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 200,
                      },
                    }}>
                    <MenuItem onClick={this.handleClose}>
                      <Button color='inherit'><i className="fas fa-database mr-2"/> Database <span className={
                        dbStatus ? 'connected' : 'disconnected'
                      }/>
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button color="inherit" className='text-muted'><span>Help <i
                        className="fa fa-question-circle text-muted"/> </span> </Button>
                    </MenuItem>
                    <MenuItem onClick={this.handleClickOpen}>
                      <Button color="inherit" className='text-muted'>About</Button>
                    </MenuItem>
                  </Menu>
                </div>
              </Toolbar>
              <div>
                {open ? <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={4000}
                    onClose={this.closeSnackBar}
                  >
                    <MySnackbarContentWrapper
                      onClose={this.closeSnackBar}
                      variant="success"
                      message="Database connected"
                    />
                  </Snackbar> :
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={4000}
                    onClose={this.closeSnackBar}
                  >
                    <MySnackbarContentWrapper
                      variant="error"
                      className={classes.margin}
                      message="Database not connected"
                    />
                  </Snackbar>
                }
              </div>

            </AppBar>
            <nav className={`exo-breadcrumb-gradient ${classes.paper}`}>
              <Breadcrumbs separator={<NavigateNextIcon/>} arial-label="Breadcrumb">
                <NavLink color="inherit" to="/chipfiles" activeClassName="active"
                         className='navlink' onMouseEnter={event => {
                  this.adaptTip(event, 'import')
                }} onMouseLeave={event => {
                  this.adaptTip(event, 'none')
                }}>
                  <i className="fa fa-upload mr-1" style={{fontSize: '12px'}}/>Import Chip Files
                </NavLink>
                <NavLink color="inherit" to="/prescan" activeClassName="active"
                         className='navlink' onMouseEnter={event => {
                  this.adaptTip(event, 'prescan')
                }} onMouseLeave={event => {
                  this.adaptTip(event, 'none')
                }}>
                  PreScan Chips</NavLink>
                <NavLink color="textLight" to="/postscan" activeClassName="active"
                         className='navlink' onMouseEnter={event => {
                  this.adaptTip(event, 'postscan')
                }} onMouseLeave={event => {
                  this.adaptTip(event, 'none')
                }}>
                  PostScan Chips</NavLink>
                <NavLink color="textLight" to="/analyze" activeClassName="active"
                         className='navlink' onMouseEnter={event => {
                  this.adaptTip(event, 'analyze')
                }} onMouseLeave={event => {
                  this.adaptTip(event, 'none')
                }}>
                  Analyze Results</NavLink>
              </Breadcrumbs>
            </nav>
            <div style={{minHeight: '80%'}}>
              <Switch>
                <Route path='/' exact component={Mainmenu}/>
                <Route path='/sampleoptions' component={SampleOptions}/>
                <Route path='/chipfiles' component={Chipfiles}/>
                <Route path='/prescan/presummary' component={PrescanSummary}/>
                <Route path='/prescan' component={Selectchips}/>
                <Route path='/loading' component={LoadScreen}/>
                <Route path='/postscan/channelselection' component={ScanSelected}/>
                <Route path='/postscan/postsummary' component={PostSummary}/>
                <Route path='/postscan' component={PostScan}/>
                <Route path='/analyze/analyzesummary' component={AnalyzeResults}/>
                <Route path='/analyze/loadAnalysis' component={LoadAnalysis}/>
                <Route path='/analyze' component={Analyze}/>
                <Route path='/sample' component={Sample}/>
              </Switch>
            </div>
            <hr/>
            <footer>
              <small className='row col-md-12'>
                <span className='col-md-5'></span>
                <span className='col-md-3'>ExoView &copy; 2019</span>
                <span className='col-md-4'><div> <h4 className='mr-2 p-1'
                                                     style={{float: "left", border: "2px solid black"}}>RUO</h4>
                FOR RESEARCH USE ONLY. <br/>
               NOT FOR USE IN DIAGNOSTIC PROCEDURES</div></span>
              </small>
            </footer>
          </div>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tips: state.tips,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(prescanActions, dispatch)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps),
  withStyles(styles))(App);
