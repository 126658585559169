import React, { Component } from 'react'
import axios from 'axios'
import config from '../../config.json'
import compose from 'recompose/compose';
import {connect} from 'react-redux';

let counter = 0;

function createData(chipName, kitId, kitType) {
  counter += 1;
  const anim = counter % 2;
  return {id: counter, chipName: chipName, kitId: kitId, kitType: kitType, anim: anim};
}

class ChipDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            data:[]

        }
    }
    componentDidMount() {
        axios.get(config.apiUrl + '/displayechips', {
          params: {
            exptid: this.props.plates.postscan_id
          }
        })
          .then(response => {
            console.log(response.data);
            const res = response.data;
    
            const arr = [];
            for (var val in res) {
              arr.push(createData( res[val].chip_name,res[val].plate_name,res[val].kit_type,))
            }
            this.setState({
              data: arr
            })
          })
    }
        
  render() {
      const {data}=this.state;
    return (
      <div style={{marginLeft:22}}>
        <div className="row col-md-12 py-2 chip-header">
          <div className="col-md-4">
            <span style={{fontSize: 14, fontWeight: 'bold'}}>Chip Name</span>
          </div>
          <div className="col-md-4">
            <span style={{fontSize: 14, fontWeight: 'bold'}}>Kit ID</span>
          </div>
          <div className="col-md-4">
            <span style={{fontSize: 14, fontWeight: 'bold'}}>Kit Type</span>
          </div>
        </div>

        {data.map(chip => {
          console.log(chip.anim);
          return (
            <div key={chip.id}>
              <div className={chip.anim === 0 ? `row col-md-12 chip-row` : `row col-md-12`}>
                <div className="col-md-4">
                  <span>{chip.chipName}</span>
                </div>
                <div className="col-md-4">
                  <span>{chip.kitId}</span>
                </div>
                <div className="col-md-4">
                  <span>{chip.kitType}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
    return {
      plates: state.plates
    }
  }
  
  export default compose(
    connect(mapStateToProps))(ChipDetails);


// export default ChipDetails;
