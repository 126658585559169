import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import KitTable from "../Analyze/AnalyzeSummary";
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: "center",
    flexWrap: "wrap"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  paper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    backgroundColor: "#e3f2fd"
  },
  paper2: {
    padding: `${theme.spacing.unit * 2}px`
  },
  navLink: {
    display: "block",
    color: "#757575",
    fontSize: "14px",
    textDecoration: "none"
  },
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: "none"
  },
  emphasisChips: {
    textDecoration: "underline",
    color: "#2980b9"
  }
});

class SampleOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }

  navigate(link) {
    this.props.history.push(link);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className="container">
          <div className="row col-md-12">
            <span className="display-4" style={{ fontSize: 24, marginTop: 20, marginLeft: -25 }}>
              Summary
            </span>
          </div>
        </div>
        <div className="ml-5">
          <div className="col-md-12">
            <KitTable />
          </div>
        </div>
        <form className="container  mt-3">
          <div className="form-group row col-sm-12 ml-5">
            <div className="row col-sm-9">
              <label htmlFor="inputSearch" className="col-sm-3 mt-1 ml-4">
                Save Experiment as:
              </label>
              <input
                type="text"
                className="form-control col-sm-6"
                id="inputSearch"
                placeholder="Postscan 2019-03-25 10:47"
              />
            </div>
            {/*<span className={`col-sm-2 mt-2`}><Link to='/sampleoptions' className={classes.emphasisChips}>4 chips</Link></span>*/}
          </div>
        </form>
      </div>
    );
  }
}
export default withStyles(styles)(SampleOptions);
