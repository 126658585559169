import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import {lighten} from '@material-ui/core/styles/colorManipulator';
import axios from 'axios';
import {bindActionCreators} from "redux";
import * as prescanActions from '../../actions/prescanActions';
import compose from 'recompose/compose'
import {connect} from 'react-redux';
import config from '../../config.json'
import {FormControl} from "react-bootstrap";
import FilterResults from "react-filter-search";

let counter = 0;

function createData(kitId, kitType, manufdate, available, plate_id, type) {
  counter += 1;
  return {id: counter, kitId, kitType, manufdate, available, plate_id: plate_id, type: type};
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {id: 'kitId', numeric: false, disablePadding: true, label: 'Kit ID'},
  {id: 'kitType', numeric: true, disablePadding: false, label: 'Kit Type'},
  {id: 'manufdate', numeric: true, disablePadding: false, label: 'Manufacturing Date'},
  {id: 'available', numeric: true, disablePadding: false, label: 'Available'},
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              color='primary'
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten("#8acff1", 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const {numSelected, classes} = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Kits
        </Typography>
      </div>
      <div className={classes.spacer}/>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 5,
  },
  table: {
    minWidth: 100,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  postscanDescription:{
    border: "1px solid #DCDCDC",
    padding: 10,
    borderRadius: 6,
    fontSize:13
  },
  postscanName:{
    "&:hover $postscanDescription":{
      visibility:"inherit",
    },
    marginTop: -10,
    marginBottom: -30
  }
});

class KitTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'available',
      selected: [],
      term: '',
      plates: [],
      data: [],
      page: 0,
      rowsPerPage: 5,
    };
  }

  searchHandler = (event) => {
    event.preventDefault();
    this.setState({term: event.target.value})
  };

  componentWillMount() {
    if (this.props.plates.key !== 0) {
      axios.get(config.apiUrl + '/getePlates', {
        params: {
          exptid: this.props.plates.key
        }
      })
        .then(response => {
          const plateArr = [];
          const res = response.data;
          this.props.actions.prescanned(true);
          for (var val in res) {
            plateArr.push(createData(res[val].plate_name, res[val].chiptype, res[val].manufucturing_date, res[val].available, res[val].plate_id, 'pre'))
          }
          axios.get(config.apiUrl + '/getAllPlates')
            .then(response => {
              const arr = [];
              const res = response.data;
              for (var val in res) {
                arr.push(createData(res[val].plate_name, res[val].chiptype, res[val].manufucturing_date, res[val].available, res[val].plate_id, 'post'))
              }
              const temp = plateArr.concat(arr);
              this.setState({
                data: temp
              })
            })
        })
    } else {
      axios.get(config.apiUrl + '/getAllPlates')
        .then(response => {
          const arr = [];
          const res = response.data;
          for (var val in res) {
            arr.push(createData(res[val].plate_name, res[val].chiptype, res[val].manufucturing_date, res[val].available, res[val].plate_id, 'post'))
          }
          this.setState({
            data: arr
          })
        })
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({order, orderBy});
  };

  handleChips = (event, id, type) => {
    const handler = this.props.handler;
    const {selected} = this.state;
    {
      selected && handler(id, type)
    }
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({selected: state.data.map(n => n.id)}));
      return;
    }
    this.setState({selected: []});
  };

  handleClick = (event, id) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };

  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const {classes} = this.props;
    const {data, order, orderBy, selected, term, isSelected, rowsPerPage, page} = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div>
        <div className='row'  style={{marginLeft: 20}}>
        <div className="col-md-6">
            <FormControl type="text"
                        placeholder='Search'
                        className='col-md-10'
                       
                        onChange={event => this.searchHandler(event)}
                        value={term}
            />
        </div>
        <div className="col-md-2" className={classes.postscanName}>
          {this.props.tips.tip_name === 'prescan' &&<p className={classes.postscanDescription}>The baseline scan of a chip normally <br/>carried out with the violet channel</p>}
          {this.props.tips.tip_name === 'postscan' &&<p className={classes.postscanDescription}>Secondary scan of a chip normally carried out<br/> with the violet, red, green and blue channels</p>}
          {this.props.tips.tip_name === 'analyze' &&<p className={classes.postscanDescription}>Click here to view Pre and Post Scan<br/> elements and to combine experiments</p>}
        </div>
        </div>
        <Paper className={classes.root}>
          <EnhancedTableToolbar numSelected={selected.length}/>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <FilterResults
                value={term}
                data={data}
                renderResults={results => (
                  <TableBody>
                    {stableSort(results, getSorting(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(n => {
                        const isSelected = this.isSelected(n.id);
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={n.id}
                            role="checkbox"
                            onClick={event => {
                              this.handleChips(event, n.plate_id, n.type);
                              this.handleClick(event, n.id)
                            }}
                            aria-checked={isSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox checked={isSelected} color="primary"/>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">{n.kitId}</TableCell>
                            <TableCell align="right">{n.kitType}</TableCell>
                            <TableCell align="right">{n.manufdate}</TableCell>
                            <TableCell align="right">{n.available}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{height: 49 * emptyRows}}>
                        <TableCell colSpan={6}/>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              />
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

KitTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    plates: state.plates,
    tips: state.tips,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(prescanActions, dispatch)
  };
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(KitTable);
