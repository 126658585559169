import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import SummaryTable from './PostscanSummary';
import {connect} from "react-redux";
import compose from 'recompose/compose';
import LoadPost from './loadPostscan';
import axios from "axios";
import PropTypes from "prop-types";
import config from '../../config.json'
import * as prescanActions from "../../actions/prescanActions";
import {bindActionCreators} from "redux";
//snackbar
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import green from '@material-ui/core/colors/green';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import amber from '@material-ui/core/colors/amber';
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

const styles = theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  paper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    backgroundColor: "#e3f2fd",
  },
  paper2: {
    padding: `${theme.spacing.unit * 2}px`,
  },
  navLink: {
    display: "block",
    color: "#757575",
    fontSize: "14px",
    textDecoration: "none",
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  emphasisChips: {
    textDecoration: 'underline',
    color: '#2980b9'
  },
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function MySnackbarContent(props) {
  const {classes, className, message, onClose, variant, ...other} = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)}/>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon}/>
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

class PostSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      enabled: true,
      newExptName: '',
      status: '',
      open: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    // this.navigate = this.navigate.bind(this);
  }

  toggleMenu() {
    this.setState({menu: !this.state.menu})
  }

  navigate = event => {
    this.props.history.goBack();
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open: false});
  };

  changeName = event => {
    this.setState({
      newExptName: event.target.value
    })
  };

  modifyExprName = event => {
    event.preventDefault();
    axios.get(config.apiUrl + '/modifyExperimentName', {
      params: {
        exptid: this.props.plates.postscan_id,
        newName: this.state.newExptName
      }
    })
      .then(response => {
        if (response.data.status === 200) {
          this.props.actions.postScanName(this.state.newExptName);
          this.setState({
            status: 'success',
            open: true
          });
        } else {
          alert('No changes made, try again.')
        }
      })
  };


  startBackgroundAnalysis = () => {
    this.setState({
      enabled: false,
    })
  };

  render() {
    const {classes} = this.props;
    const {enabled, open, status} = this.state;
    console.log(this.props.plates.postname);
    return (
      <div className={classes.root} style={{marginRight: 50}}>
        <div className='row col-md-12 ml-3'>
          {enabled ? (
              <div className="row col-md-12 " style={{marginTop: 20, marginLeft: 25}}>
                <span className='col-md-4 text-secondary'
                      style={{fontSize: 24}}>Summary</span>

                <span className="col-md-4"/>
                <span className='col-md-4'>
                  <Tooltip title="Download Report">
                    <a
                      className="btn exo-btn shadow-sm"
                      href={config.apiUrl + "/experimentsummary?exptid=" + this.props.plates.postscan_id}
                      download="report"
                      target='_blank'
                    >
                      Download <GetAppIcon />
                    </a>
                  </Tooltip>
                </span>
              </div>
            ) :
            (<span className='mt-3 ml-5' style={{fontSize: 16}}>

            </span> )
          }
        </div>
        {enabled ?
          (
            <div className="ml-5">
              <div className="col-md-12">
                <SummaryTable/>
              </div>
              <div className="row ml-4">
                <div className="col-md-6">
                  <form className='container  mt-3' onSubmit={event => this.modifyExprName(event)}>
                    <div className="form-group row col-md-12">
                      <div className="row col-md-9">
                        <label htmlFor="inputSearch" className="col-md-4 mt-1">Save Experiment as:</label>
                        <input type="text" className="form-control col-sm-6" id="inputSearch"
                               placeholder={this.props.plates.postname}
                               onChange={event => this.changeName(event)}/>
                      </div>
                      <span className={`col-md-2 mt-2`} style={{marginLeft: 100}}>
                      {/*<Link to='/sampleoptions' className={classes.emphasisChips}>4 chips</Link>*/}
                    </span>
                    </div>
                  </form>
                </div>
                <div>
                  {
                    open && status === 'success' &&
                    <Snackbar
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      open={this.state.open}
                      autoHideDuration={2000}
                      onClose={this.closeSnackBar}
                    >
                      <MySnackbarContentWrapper
                        onClose={this.closeSnackBar}
                        variant="success"
                        message="Name changed successfully!"
                      />
                    </Snackbar>
                  }
                </div>
                <div className="col-md-6">
                    <button className="exo-btn shadow select-file" type="button"
                            style={{padding: '6px 12px'}} onClick={this.navigate}><i className="fas fa-chevron-circle-left mr-1" style={{fontSize: '13px'}}/>Back to Channel Selection
                    </button>
                  <button className="exo-btn shadow select-file ml-5 col-md-3" type="button"
                          style={{marginTop: 20, padding: '6px 12px'}}
                          onClick={() => this.startBackgroundAnalysis()}>Start Scan <i class="fas fa-play-circle ml-1"
                                                                                       style={{fontSize: '13px'}}/>
                  </button>

                </div>
              </div>
            </div>) :
          (<div>
            <LoadPost handler={this.launchScan}/>
          </div>)
        }

      </div>
    )
  }
}


function mapStateToProps(state, ownProps) {
  return {
    plates: state.plates
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(prescanActions, dispatch)
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(PostSummary);
