import * as types from './actionTypes';

export function createPreScan(prescan) {
  return {type: types.CREATE_SCAN, prescan};
}

export function createExperiment(experiment) {
  return {type: types.CREATE_EXPER, experiment};
}

export function experimentName(name) {
  return {type: types.EXPERIMENT_NAME, name};
}

export function postScanName(postname) {
  return {type: types.POSTSCAN_NAME, postname};
}

export function permanentName(permanentName) {
  return {type: types.PERMANENT_NAME, permanentName};
}

export function postScanId(postscan_id) {
  return {type: types.POSTSCAN_ID, postscan_id};
}

export function selectedExperiment(exptid) {
  return {type: types.SELECTED_EXPT, exptid};
}

export function prescanned(prescanned) {
  return {type: types.PRESCANNED, prescanned};
}

export function analysisId(analysis_id) {
  return {type: types.ANALYSIS_ID, analysis_id};
}

export function analysisName(analysis_name) {
  return {type: types.ANALYSIS_NAME, analysis_name};
}

export function analysisExperiment(kitId) {
  return {type: types.ANALYSIS_EXPERIMENT, kitId};
}

//Tips
export function saveTip(tip_name) {
  return {type: types.SHOW_TIP, tip_name};
}



